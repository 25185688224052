import { Injectable } from '@angular/core';
import { endOfMonth, endOfYear, isValid, startOfMonth, startOfYear, subDays, subMonths, addDays, addYears, differenceInDays, format, intervalToDuration, isSameDay, parse, subYears } from 'date-fns'

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
  todayDate = format(new Date(), "yyyy-MM-dd")
  constructor() {
  }

  getDF1(): string {
    return format(new Date(), "dd MMM, yyyy")

  }
  /* This function will return a date in YYYY-MM-DD format */
  getDF2(): string {
    return format(new Date(), "yyyy-MM-dd")

  }
  /* This function will return a date in MMM DD, YYYY format */
  getDF3(): string {
    return format(new Date(), "MMM dd, yyyy")

  }
  /* This function will return a date in DD/MM/YYYY format */
  getDF4(): string {
    return format(new Date(), "dd/MM/yyyy")
  }

  /* This function will return a date in YYYY-MM-DD format */
  getInputDF1(date): string {
    if (date != '' && date != undefined && date != null) {
      return format(new Date(date), "yyyy-MM-dd")
    }
    return
  }
  /* This function will return a date in DD MMM, YYYY format */
  getInputDF2(date): any {
    if (date != '' && date != undefined && date != null) {
      return format(new Date(date), "dd MMM, yyyy")
    }
    return
  }
  /* This function will return a date in MMM DD, YYYY format */
  getInputDF3(date): string {
    if (date != '' && date != undefined && date != null) {
      return format(new Date(date), "MMM dd, yyyy")
    }
    return
  }
  /* This function will return a date in DD/MM/YYYY format */
  getInputDF4(date): string {
    if (date != '' && date != undefined && date != null) {
      return format(new Date(date), "dd/MM/yyyy")
    }
    return
  }

  getInputDF5(dateStr): string {
    if (dateStr != '' && dateStr != undefined && dateStr != null) {
      let date = parse(dateStr, 'dd-MM-yyyy', new Date());
      const formattedDate = format(new Date(date), 'dd MMM, yyyy');
      return formattedDate
    }
    return
  }

  getInputDF6(date): string {
    if (date != '' && date != undefined && date != null) {
      return format(new Date(date), "dd-MM-yyyy")
    }
    return
  }
  getInputDF7(dateStr): string {
    let date = parse(dateStr, 'dd/MM/yyyy', new Date());
    if (!isValid(date)) {
      date = parse(dateStr, 'MM/dd/yyyy', new Date());
    }
    const formattedDate = format(new Date(date), 'MMM dd yyyy');
    return formattedDate

  }

  /* *********************************** fromDate (start) *********************************** */

  /* This function will return fromDate ( last 3 months ) in DD MMM, YYYY format */
  getFromDate(numberOfDays): string {
    return format(subDays(new Date(), numberOfDays), "dd MMM, yyyy")
  }
  /* This function will return fromDate ( last 3 months ) in DD MMM, YYYY format */
  getFromDate1(months): string {
    return format(subMonths(new Date(), months), "dd MMM, yyyy")
  }

  /* This function will return fromDate of current month in DD MMM, YYYY format */
  getCurrentMonthFromDate(): string {
    return format(startOfMonth(new Date()), "dd MMM, yyyy")
  }

  /* This function will return fromDate of last month in DD MMM, YYYY format */
  getLastMonthFromDate(month): string {
    return format(startOfMonth(subMonths(new Date(), month)), "dd MMM, yyyy")

  }

  /* *********************************** fromDate (end) *********************************** */

  /* This function will return toDate of last month in DD MMM, YYYY format */
  getLastMonthToDate(month): string {
    return format(endOfMonth(subMonths(new Date(), month)), "dd MMM, yyyy")

  }

  /* This function will return yearStartDate */
  getYearStartDate(): string {
    return format(startOfYear(new Date()), 'yyyy-MM-dd')
  }
  /* This function will return yearEndDate */
  getYearEndDate(): string {
    return format(endOfYear(new Date()), 'yyyy-MM-dd')
  }
  /* This function will return yearStartDate in DD/MM/YYYY format */
  getYearStartDate1(): string {
    return format(startOfYear(new Date()), 'dd/MM/yyyy')
  }

  /* *********************************** monthStart (start) *********************************** */

  /* This function will return monthStart date of current month in YYYY-MM-DD format */
  getCurrentMonthStart(): string {
    return format(startOfMonth(new Date()), "yyyy-MM-dd")

  }
  /* This function will return monthStart date of current month in DD/MM/YYYY format */
  getCurrentMonthStart1(): string {
    return format(startOfMonth(new Date()), "dd/MM/yyyy")
  }

  /* This function will return monthStart date of last month in YYYY-MM-DD format */
  getLastMonthStart(month): string {
    return format(startOfMonth(subMonths(new Date(), month)), "yyyy-MM-dd")

  }
  /* This function will return monthStart date of last month in DD/MM/YYYY format */
  getLastMonthStart1(month): string {
    return format(startOfMonth(subMonths(new Date(), month)), "dd/MM/yyyy")
  }
  /* This function will return monthStart date of last month in YYYY-MM-DD format */
  getLastMonthStart2(month): string {
    return format(startOfMonth(subDays(new Date(), month)), "yyyy-MM-dd")
  }
  /* This function will return monthStart date of last 3 months, last 6 months in YYYY-MM-DD format */
  getLastFewMonthsStart(numberOfDays): string {
    return format((subDays(new Date(), numberOfDays)), "yyyy-MM-dd")
  }
  /* This function will return monthStart date of last month, last 3 months, last 6 months in DD/MM/YYYY format */
  getLastFewMonthsStart1(numberOfDays): string {
    return format(startOfMonth(subDays(new Date(), numberOfDays)), "dd/MM/yyyy")
  }
  /* *********************************** monthStart (end) *********************************** */

  /* *********************************** monthEnd (start) *********************************** */

  /* This function will return monthEnd date of current month, last 3 months, last 6 months in YYYY-MM-DD format */
  getMonthEnd(): string {
    return format(endOfMonth(new Date()), "yyyy-MM-dd")
  }
  /* This function will return monthEnd date of current month, last 3 months, last 6 months in dd/MM/yyyy format */
  getMonthEnd1(): string {
    return format(endOfMonth(new Date()), "dd/MM/yyyy")
  }

  /* This function will return monthEnd date of last month in YYYY-MM-DD format */
  getLastMonthEnd(month): string {
    let newDate = new Date();
    newDate.setDate(0);
    return format(newDate, "yyyy-MM-dd").toString()
  }

  /* This function will return monthEnd date of last month in DD/MM/YYYY format */
  getLastMonthEnd1(month): string {
    let newDate = new Date();
    newDate.setDate(0);
    return format(newDate, "dd/MM/yyyy").toString()
  }
  /* *********************************** monthEnd (end) *********************************** */

  /* This function will return Reminder Timestamp Time in YYYY-MM-DD H:mm:ss format */
  getTimestamp(date): string {
    if (!isValid(new Date(date))) {
      return
    } else {
      return format(new Date(date), "yyyy-MM-dd H:mm:ss").toString()
    }
  }

  /* This function will return today's date in YYY-MM-DD H:mm:ss format */
  getTodayDateTmpDF1(): string {
    return format(new Date(), "yyy-MM-dd H:mm:ss").toString()
  }

  /* This function will return today's date in YYY-MM-DD format */
  getTodayDateTmpDF2(): string {
    return format(new Date(), "yyy-MM-dd").toString()
  }

  /* This function will return today's date in YYY-MM-DD format */
  getTodayDateTmpInputDF(date): string {
    if (!isValid(new Date(date))) {
      return
    } else {
      return format(new Date(date), "yyy-MM-dd").toString()
    }
  }

  /* This function will return hours */
  getHours(date1, date2): number {
    if (!isValid(new Date(date1)) || !isValid(new Date(date2))) {
      return
    } else {
      let duration = intervalToDuration({
        start: new Date(date1),
        end: new Date(date2)
      })
      return duration.hours
    }
  }
  /* This function will return minutes */
  getMinutes(date1, date2): number {
    if (!isValid(new Date(date1)) || !isValid(new Date(date2))) {
      return
    } else {
      let duration = intervalToDuration({
        start: new Date(date1),
        end: new Date(date2)
      })
      return duration.minutes
    }
  }
  /* This function will return current time */
  getCurrentTime(): string {
    let newDate = new Date();
    return format(newDate, "H:mm:ss").toString()
  }

  /*   This function will return the difference of days in comparison to current date
  0 -> current date
  positive value
  */
  getDiff1(date): number {
    if (!isValid(new Date(date))) {
      return
    } else {
      let duration = intervalToDuration({
        start: new Date(),
        end: new Date(date)
      })
      if (new Date() > new Date(date)) {
        return -duration.days
      } else {
        return duration.days
      }
    }
  }

  /* This function will check if the format is same */
  compareDate(date): boolean {
    if (!isValid(new Date(date))) {
      return
    } else {
      return isSameDay(new Date(date), new Date(format(new Date(date), 'yyyy-MM-dd')))
    }
  }

  /* This function will check if two dates are same */
  compare2Dates(date1, date2): boolean {
    if (!isValid(new Date(date1)) || !isValid(new Date(date2))) {
      return
    } else {
      return isSameDay(new Date(date1), new Date(date2))
    }
  }

  /* This function will return timestamp in the 12 hour time format */
  get12HourDF(date): string {
    if (!isValid(new Date(date))) {
      return
    } else {
      return format(new Date(date), 'hh:mm:ss')
    }
  }

  getMoment(date): string {
    if (!isValid(new Date(date))) {
      return
    } else {
      return format(new Date(date), "yyyy-MM-dd").toString()
    }
  }

  getMoment1(date): string {
    if (!isValid(new Date(date))) {
      return
    } else {
      return format(new Date(date), "dd MM, yyyy").toString()
    }
  }

  /* This function will return duration in days in YYYY-MM-DD format */
  addDays1(numberOfDays): string {
    return format(addDays(new Date(), numberOfDays), "yyyy-MM-dd")
  }

  /* This function will return duration in days in DD MMM, YYYY format */
  addDays2(numberOfDays): string {
    return format(addDays(new Date(), numberOfDays), "dd MMM, yyyy")
  }

  /* This function will return duration in days in DD MMM YYYY format */
  getAddMoment2(duration): string {
    return format(addDays(new Date(), duration), "dd MMM yyyy")
  }

  /* This function will return duration in years in YYYY-MM-DD format */
  addYear(numberOfYears): string {
    return format(addYears(new Date(), numberOfYears), "yyyy-MM-dd")
  }

  /* This function will return time in the provided format */
  getTime(type): string {
    return format(new Date(), type)
  }

  getMinAgeDate(years) {
    return subYears(new Date(), years)
  }
  getDiffInDays(date1, date2): number {
    if (!isValid(new Date(date1)) || !isValid(new Date(date2))) {
      return
    } else {
      return differenceInDays(new Date(date1), new Date(date2))
    }
  }

  getDF0(date): string {
    if (!isValid(new Date(date))) {
      return
    } else {
      return format(new Date(date), "dd MMM yyyy")
    }
  }
  getNextMonthLastDate(month): string {
    return format(endOfMonth(subMonths(new Date(), month)), "dd MMM, yyyy")
  }

  addDays3(date, numberOfDays): string {
    return format(addDays(new Date(date), numberOfDays), "dd MMM, yyyy")
  }

}
