import { Component, Input } from '@angular/core';

@Component({
  selector: 'hb-content-segment',
  templateUrl: './content-segment.component.html',
  styleUrls: ['./content-segment.component.scss'],
})

export class ContentSegmentComponent {
  @Input() forCredit: string;
}
