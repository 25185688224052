import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { MobileNativeSettings } from "src/app/_services/nativeSettings.service";
import { GV } from 'src/app/variables';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-file-permission',
  templateUrl: './file-permission.page.html',
  styleUrls: ['./file-permission.page.scss'],
})
export class FilePermissionPage {

  os: string = GV.OS;
  deniedAlways: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    private navParams: NavParams,
    private nativeSettings: MobileNativeSettings,
    public utilityService: UtilityService
  ) {
    this.deniedAlways = this.navParams.get('deniedAlways');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  selectOption(option) {
    this.modalCtrl.dismiss({ option: option });
  }

  gotoAppInfo() {
    this.modalCtrl.dismiss();
    this.nativeSettings.Open()
  }

}
