import { Pipe, PipeTransform } from '@angular/core';
import { GV } from '../variables';
import { UtilityService } from '../_services/utility.service';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
  shortNumber: any;
  currency = GV.CountryCurrency
  constructor(private utilityService: UtilityService) {
  }

  transform(numberTmp): any {
    if (isNaN(numberTmp) == true) {
      numberTmp = numberTmp.toString().replaceAll(',', '');
    }
    let number = +numberTmp
    if (isNaN(number)) return this.currency + number; // will only work value is a number
    if (number === null) return null;
    if (number === 0) return this.currency + number + '.00';
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 2);
    const isNegative = number < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [];

    powers.push(
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'Cr', value: Math.pow(10, 7) },
      { key: 'L', value: Math.pow(10, 5) },
      { key: 'K', value: 1000 }
    )

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    return this.currency + this.utilityService.formatCurrency(number, key, abs, isNegative)
  }
}
