import { Component } from '@angular/core';
import { GV } from "src/app/variables";
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'hb-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {

  os: string = GV.OS;

  constructor(
    public utilityService: UtilityService
  ) { }
}
