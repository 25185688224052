import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilityService } from 'src/app/_services/utility.service';
import { GV } from 'src/app/variables';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.page.html',
  styleUrls: ['./terms-conditions.page.scss'],
})
export class TermsConditionsPage {
  os: string = GV.OS;
  showAccept: boolean = true
  constructor(
    private modalCtrl: ModalController,
    public utilityService: UtilityService,
    private navParams: NavParams,
  ) { this.showAccept = this.navParams.get('showAccept'); }

  goBack() {
    this.modalCtrl.dismiss();
  }

  acceptTermsConditions() {
    this.modalCtrl.dismiss('accepted')
  }

}
