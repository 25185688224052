import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/_services/api.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ValidatorService } from 'src/app/_services/validator.service';
import { Enums } from 'src/app/enums';
import { GV } from 'src/app/variables';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.page.html',
  styleUrls: ['./add-user.page.scss'],
})
export class AddUserPage implements OnInit {

  subscription: any;
  addUserForm: FormGroup
  nameErr: boolean = false;
  os: string = GV.OS
  Cashflow: boolean = false;
  Compliance: boolean = false;
  trade: boolean = false;
  emailErr: boolean = false;
  mobileNoErr: boolean = false;
  GSTErr: boolean = false;
  step1: boolean = true;
  step2: boolean = false;
  fNameErr: boolean = false;
  lNameErr: boolean = false;
  companyNameErr: boolean = false;
  companyNameErrMsg: string = "";
  urlErr: boolean = false
  IsNotAll: boolean;
  packageList: Array<any> = [];
  selectedPAckage: string = "";
  unqiuePackages: Array<any> = [];

  urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  @ViewChild("fNameCtrl", { static: false }) fNameCtrl;
  @ViewChild("lNameCtrl", { static: false }) lNameCtrl;
  @ViewChild("emailErrCtrl", { static: false }) emailErrCtrl;
  @ViewChild("MobileNoCtrl", { static: false }) MobileNoCtrl;
  @ViewChild("companyNameCtrl", { static: false }) companyNameCtrl;
  @ViewChild('urlCtrl', { static: false }) urlCtrl;
  @ViewChild('GSTCtrl', { static: false }) GSTCtrl;

  constructor(
    private platform: Platform,
    private fb: FormBuilder,
    public utilityService: UtilityService,
    private modalCtrl: ModalController,
    public validatorService: ValidatorService,
    private apiService: ApiService,
    private loadingCtrl: LoadingController,
  ) {
    this.getSubscriptionPackageReport();
  }

  ngOnInit() {
    this.addUserForm = this.fb.group({
      fName: [""],
      lName: [""],
      email: [""],
      MobileNo: [""],
      companyName: [""],
      url: ['', [Validators.pattern(this.urlReg)]],
      GST: [""],
    })
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribeWithPriority(10000, () => {
      this.goBack();
    })
  }
  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }
  validateFName(event) {
    this.fNameErr = false;
    this.addUserForm.patchValue({
      fName: this.validatorService.validateText1(event.target.value)
    })
  }
  validatelName(event) {
    this.lNameErr = false;
    this.addUserForm.patchValue({
      lName: this.validatorService.validateText1(event.target.value)
    })
  }

  validateMobileNo(event: any) {
    this.mobileNoErr = false;
    let value = event.target.value;
    if (!this.validatorService.validateMobile(value)) {
      event.target.value = value.slice(0, -1);
      this.addUserForm.patchValue({
        MobileNo: event.target.value,
      });
    }
  }
  validateEmail(event) {
    this.emailErr = false;
    this.addUserForm.patchValue({
      email: this.validatorService.validateText(event.target.value)
    })
  }

  validateGST(event) {
    this.GSTErr = false;
    let value = event.target.value;
    if (!this.validatorService.isAlphaNumeric(value)) {
      event.target.value = value.slice(0, -1);
      this.addUserForm.patchValue({
        GST: event.target.value
      })
    }
    if (this.validatorService.isValueNotEmpty(event.target.value)) {
      this.addUserForm.patchValue({
        GST: event.target.value.toUpperCase()
      })
    }
  }

  validateCompanyName(event) {
    this.companyNameErr = false;
    this.addUserForm.patchValue({
      companyName: this.validatorService.validateText1(event.target.value)
    })
  }

  onSubmit() {
    this.fNameErr = false;
    this.lNameErr = false;
    this.emailErr = false;
    this.mobileNoErr = false;
    this.companyNameErr = false;
    this.urlErr = false;
    this.GSTErr = false;

    if (this.step1) {
      let fName = this.addUserForm.value.fName.trim();
      if (this.validatorService.isValueEmpty(fName)) {
        this.fNameCtrl.el.children[0].focus();
        // this.fNameErrMsg = "Please enter your first name";
        this.fNameErr = true;
        return;
      }
      if (!this.validatorService.isAlphabetic(fName.trim())) {
        this.fNameCtrl.el.children[0].focus();
        // this.fNameErrMsg = "Please enter a valid value";
        this.fNameErr = true;
        return;
      }
      let lName = this.addUserForm.value.lName.trim();
      if (this.validatorService.isValueEmpty(lName)) {
        this.lNameCtrl.el.children[0].focus();
        // this.lNameErrMsg = "Please enter your last name";
        this.lNameErr = true;
        return;
      }
      if (!this.validatorService.isAlphabetic(lName.trim())) {
        this.lNameCtrl.el.children[0].focus();
        // this.lNameErrMsg = "Please enter a valid value";
        this.lNameErr = true;
        return;
      }


      let mobileNo = this.addUserForm.value.MobileNo;
      if (this.validatorService.isValueNotEmpty(mobileNo)) {
        if (mobileNo.length !== 10 || +(mobileNo.substring(0, 1)) < 6) {
          // this.mobileNoErrMsg = "Please enter a valid value"
          this.mobileNoErr = true;
          this.MobileNoCtrl.el.children[0].focus();
          return
        }
      }

      let email = this.addUserForm.value.email
      if (this.validatorService.isValueEmpty(email)) {
        this.emailErrCtrl.el.children[0].focus();
        // this.emailErrMsg = "Please enter email address";
        this.emailErr = true;
        return;
      }
      if (!this.validatorService.validateEmail(email)) {
        this.emailErrCtrl.el.children[0].focus();
        // this.emailErrMsg = "Please enter a valid value";
        this.emailErr = true;
        return;
      }

      let GST = this.addUserForm.value.GST
      if (!this.validatorService.validateGST(GST)) {
        this.GSTCtrl.el.children[0].focus();
        this.GSTErr = true;
        return;
      }

      let companyName = this.addUserForm.value.companyName;
      if (this.validatorService.isValueEmpty(companyName)) {
        this.companyNameCtrl.el.children[0].focus();
        // this.companyNameErrMsg = "Please enter company name";
        this.companyNameErr = true;
        return;
      }

      if (!this.validatorService.isAlphaNumeric(companyName.trim())) {
        this.companyNameCtrl.el.children[0].focus();
        // this.companyNameErrMsg = "Please enter a valid value";
        this.companyNameErr = true;
        return;
      }

      let url = this.addUserForm.value.url;
      if (this.validatorService.isValueEmpty(url)) {
        this.urlCtrl.el.children[0].focus();
        this.urlErr = true;
        return;
      }
      this.step1 = false;
      this.step2 = true;
      return
    }
    if (this.validatorService.isValueEmpty(this.selectedPAckage)) {
      this.utilityService.showErrMsgTop("Please select a plan to continue")
      return
    }
    this.addRefer()
  }

  back() {
    this.step1 = true;
    this.step2 = false
  }

  selectManual(service) {
    const allItems = service.subservices.length;
    let selected = 0;

    service.subservices.map((item) => {
      if (item.isSelected) selected++;
    });

    if (selected > 0 && selected < allItems) {
      // One item is selected among all checkbox elements
      service.isSelected = false;
      // this.IsNotAll = true;
    } else if (selected === allItems) {
      // All item selected
      // this.IsNotAll = false;
      service.isSelected = true;
    } else {
      // this.IsNotAll = false;
      service.isSelected = false;
    }
  }

  selectAll(service) {
    if (service.hasOwnProperty("subservices")) {
      if (service.isSelected === true) {
        for (let subservice of service.subservices) {
          subservice.isSelected = true;
        }
      } else if (service.isSelected === false) {
        for (let subservice of service.subservices) {
          subservice.isSelected = false;
        }
      }
    }
  }

  async addRefer() {
    let reqBody = {
      FirstName: this.addUserForm.value.fName.trim(),
      LastName: this.addUserForm.value.lName.trim(),
      customer_email: this.addUserForm.value.email.toLowerCase(),
      customer_mobile: this.addUserForm.value.MobileNo,
      customerCompanyName: this.addUserForm.value.companyName,
      Gstin: this.addUserForm.value.GST,
      Website: this.addUserForm.value.url,
      PackageName: this.selectedPAckage,
      CompanyName: GV.CompanyName
    }
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      this.apiService.AddRefer(reqBody)
        .then(data => {
          const response = JSON.parse(data.data)
          ld.dismiss();
          if (response.Code.toString() !== "0") {
            this.utilityService.showErrMsgTop(response.Message);
            if (response.Code.toString() === "1") {
              this.utilityService.clearSessionStorageData();
            }
            return;
          }
          this.modalCtrl.dismiss("success")
          ld.dismiss();
        }, err => {
          ld.dismiss();
        });
    });
  }

  async getSubscriptionPackageReport() {
    let reqBody = {
      ReportName: "atlasapi_subscription_package_report",
    }
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      this.apiService.getReportAPI(reqBody)
        .then(data => {
          const response = JSON.parse(data.data)
          this.packageList = []
          let packageListtemp = []
          if (response.Code.toString() !== "0") {
            ld.dismiss();
            if (response.Code.toString() === "1") {
              this.utilityService.showErrMsgTop(response.Message);
              this.utilityService.clearSessionStorageData();
            }
            return;
          }
          let packageName = "", packageDescription = "", serviceName = "", serviceLimit = "";
          // for (let i = 0; i < 3; i++) {
          for (let row of response.Row) {
            for (let col of row.Cols) {
              if (col.Key.toString() === "package_name") {
                packageName = col.Value.toString()
              }
              if (col.Key.toString() === "package_description") {
                packageDescription = col.Value.toString()
              }
              if (col.Key.toString() === "service_name") {
                serviceName = col.Value.toString()
              }
              if (col.Key.toString() === "service_limit") {
                serviceLimit = col.Value.toString()
              }
            }
            packageListtemp.push({
              PackageName: packageName,
              PackageDescription: packageDescription,
              ServiceName: serviceName,
              IsSelected: false,
              ServiceLimit: serviceLimit
            })
            let tempPack = this.unqiuePackages.filter(item => item.PackageName == packageName)
            if (tempPack.length === 0) {
              this.unqiuePackages.push({
                PackageName: packageName,
                PackageDescription: packageDescription,
                ServiceName: serviceName,
                IsSelected: false,
                ServiceLimit: serviceLimit
              })
            }
          }
          //this.getTwoItemsPerSlide(packageListtemp)
          this.packageList = packageListtemp
          this.selectedPAckage = packageListtemp[0].PackageName
          // }
          ld.dismiss();
        }, err => {
          ld.dismiss();
        });
    });
  }

  getTwoItemsPerSlide(list) {
    for (let i = 0; i < list.length; i += 2) {
      this.packageList.push(list.slice(i, i + 2));
    }
    // return this.packageList;
  }

  selectPackage(packageData: any) {
    this.selectedPAckage = packageData.PackageName
    // for (let packs of this.packageList) {
    //   for (let packDetails of packs) {
    //     if (packDetails.PackageName = this.selectedPAckage) {
    //       packDetails.IsSelected = true
    //     } else {
    //       packDetails.IsSelected = false
    //     }
    //   }
    // }
  }

}
