import { Component, Input } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'hb-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent {

  @Input() name: string;
  @Input() required: string;
  @Input() tooltipContent: string;
  constructor(
    public utilityService: UtilityService
  ) { }
}
