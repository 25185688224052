import { Injectable } from '@angular/core';
import { Mixpanel, MixpanelPeople } from '@ionic-native/mixpanel/ngx';
import * as mixpanel from 'mixpanel-browser';
import { GV } from "src/app/variables";
import { environment } from "src/environments/environment";
import { Enums } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor(
    private mixpanelM: Mixpanel,
    private mixpanelPeople: MixpanelPeople,
  ) {

  }

  init(mixpanel_token) {
    if (GV.IsWebChannel === true) {
      mixpanel.init(mixpanel_token)

    } else {
      this.mixpanelM.init(mixpanel_token)
        .then(() => {
          this.mixpanelM.reset()
            .then(() => {
            })
        })
        .catch(err => {
          console.log("mixpanel err -" + JSON.stringify(err));
        })
    }
  }

  fireEvent(eventName, obj?: any) {
    if (GV.IsWebChannel === true) {
      mixpanel.track(eventName)
      if (obj !== null && obj !== undefined) {
        mixpanel.people.set(obj)
      }
    } else {
      this.mixpanelM.track(eventName);
      if (obj !== null && obj !== undefined) {
        this.mixpanelPeople.set(obj)
      }
    }
  }

  appOpened() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "App Opened"
    let obj = {
      'Last App Open': new Date().toISOString(),
      'Channel': GV.IsWebChannel === true ? 'Web' : 'Mobile'
    }
    this.fireEvent(eventName, obj)
  }


  logInSuccessfull(phone, email, userName, companyName, addressDetails, identificationDetails, bankDetails, pgStatus) {
    if (environment.IS_MP === false) {
      return
    }
    this.setIdentity(Enums.Country.Code + phone);
    let eventName = "[LOGIN] Logged In Successfully"
    let obj = {
      'Phone': Enums.Country.Code + phone,
      '$country': Enums.Country.Name,
      '$email': (email !== '' && email !== null && email !== undefined) ? email : 'NA',
      '$name': (userName !== '' && userName !== null && userName !== undefined) ? userName : 'NA',
      'Company Name': (companyName !== '' && companyName !== null && companyName !== undefined) ? companyName : 'NA',
      'Address Details': addressDetails === 'true' ? 'Submitted' : 'NA',
      'Identification Details': identificationDetails === 'true' ? 'Submitted' : 'NA',
      'Bank Details': bankDetails === 'true' ? 'Submitted' : 'NA',
      'PG Status': pgStatus
    }
    this.fireEvent(eventName, obj)
  }

  loginCancelled(phone) {
    if (environment.IS_MP === false) {
      return
    }
    this.setIdentity(Enums.Country.Code + phone);
    let eventName = "[LOGIN] Cancelled"
    let obj = {
      'Phone': Enums.Country.Code + phone,
      '$country': Enums.Country.Name
    }
    this.fireEvent(eventName, obj)
  }

  signUpStarted(phone) {
    if (environment.IS_MP === false) {
      return
    }
    this.setIdentity(Enums.Country.Code + phone);
    let eventName = "[SIGNUP] Started"
    let obj = {
      'Phone': Enums.Country.Code + phone,
      '$country': Enums.Country.Name
    }
    this.fireEvent(eventName, obj)
  }

  signUpSuccessfull(phone, email, userName, companyName) {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[SIGNUP] Onboarded Successfully"
    let obj = {
      'Phone': Enums.Country.Code + phone,
      '$country': Enums.Country.Name,
      '$email': (email !== '' && email !== null && email !== undefined) ? email : 'NA',
      '$name': (userName !== '' && userName !== null && userName !== undefined) ? userName : 'NA',
      'Company Name': (companyName !== '' && companyName !== null && companyName !== undefined) ? companyName : 'NA',
    }
    this.fireEvent(eventName, obj)
    this.setIdentity(Enums.Country.Code + phone);
  }

  signUpOtpVerified() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[SIGNUP] OTP Verified"
    this.fireEvent(eventName)
  }

  signUpStartGSTIN() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[SIGNUP] Start with GSTIN"
    this.fireEvent(eventName)
  }

  signUpStartPAN() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[SIGNUP] Start with PAN"
    this.fireEvent(eventName)
  }

  signUpVerifiedGSTIN() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[SIGNUP] GSTIN Verified"
    this.fireEvent(eventName)
  }

  signUpVerifiedPAN() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[SIGNUP] PAN Verified"
    this.fireEvent(eventName)
  }

  gbsStarted() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[GBS] Started"
    this.fireEvent(eventName)
  }

  gbsEmailVerified() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[GBS] Email Verified"
    this.fireEvent(eventName)
  }

  gbsGstCredential() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[GBS] GSTIN & Username submitted"
    this.fireEvent(eventName)
  }

  gbsOtpVerified() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[GBS] OTP verified"
    this.fireEvent(eventName)
  }

  gbsDashboard() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[GBS] Dashboard"
    this.fireEvent(eventName)
  }

  logoutSuccess() {
    if (environment.IS_MP === false) {
      return
    }
    let eventName = "[LOGOUT] Successfully"
    this.fireEvent(eventName)
  }


  setIdentity(mobile) {
    if (environment.IS_MP === false) {
      return
    }
    if (GV.IsWebChannel === true) {
      mixpanel.identify(mobile)
    } else {
      this.mixpanelM.identify(mobile, true)
    }
  }

  signIn() {
    this.mixpanelM.track('Sign In');
  }
}
