import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Enums } from "../enums";
import { Device } from '@capacitor/device';
import { GV } from '../variables';
import { environment } from 'src/environments/environment';
import { lastValueFrom, of } from 'rxjs';
import { delay, retryWhen, scan, switchMap, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  imsi: string = "";
  deviceInfo: any = {
    OS: "",
    OSVersion: "",
    IMEI: "11",
    IMSI: "",
    ChannelName: "Mobile",
    AppVersion: "",
    Country: Enums.Country.Name,
    CountryCode: Enums.Country.Code,
  };
  sessInfo: any = {
    Mobile: "",
    CompanyId: "",
    SessionID: "",
    UserType: "",
  };

  constructor(
    private platform: Platform,
    private httpClient: HttpClient,
  ) {
    platform.ready().then(() => {
      if (platform.is("cordova")) {
        this.deviceInfo.AppVersion = GV.Version;
        Device.getInfo()
          .then(info => {
            this.deviceInfo.OS = info.platform;
            this.deviceInfo.OSVersion = info.osVersion;
            this.deviceInfo.Manufacturer = info.manufacturer
            this.deviceInfo.Model = info.model
          })
        Device.getId()
          .then(idInfo => {
            this.deviceInfo.IMEI = idInfo.uuid;
          })
      } else {
        this.deviceInfo.ChannelName = "Web";
      }
    });
  }

  httpPost(Url: string, body: object): Promise<any> {
    if (GV.Email !== "") {
      this.sessInfo.Email = GV.Email;
      this.sessInfo.CompanyId = GV.CompanyId;
      this.sessInfo.SessionID = GV.SessionId;
      this.sessInfo.UserType = GV.UserType;
      this.sessInfo.UserId = GV.UserId;
      body = Object.assign(body, this.sessInfo);
    }
    this.deviceInfo.AppVersion = GV.Version;
    body = Object.assign(body, this.deviceInfo);

    if (body.hasOwnProperty('Currency')) {
      let bodyTmp = JSON.parse(JSON.stringify(body))
      bodyTmp.Currency = 'INR';
      body = Object.assign(body, bodyTmp);
    }

    if (body.hasOwnProperty('currency')) {
      let bodyTmp = JSON.parse(JSON.stringify(body))
      bodyTmp.currency = 'INR';
      body = Object.assign(body, bodyTmp);
    }

    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .post(environment.API_END_POINT + Url, body, { withCredentials: true })
        .pipe(retryWhen(errors => errors
          .pipe(
            switchMap((error) => {
              if (error.status === 422 || error.status === 417) {
                this.getToken()
                return of(error.status);
              }
              throw error
            }),
            scan(retryCount => retryCount + 1, 0),
            takeWhile(retryCount => retryCount < 2),
            delay(1000),
          )))
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        });
    });
  }

  httpGet(Url: string, body: object): Promise<any> {
    if (GV.Email !== "") {
      this.sessInfo.Mobile = GV.Mobile;
      this.sessInfo.CompanyId = GV.CompanyId;
      this.sessInfo.SessionID = GV.SessionId;
      this.sessInfo.UserType = GV.UserType;
      body = Object.assign(body, this.sessInfo);
    }
    this.deviceInfo.AppVersion = GV.Version;
    body = Object.assign(body, this.deviceInfo);
    const params = new HttpParams().set("body", JSON.stringify(body));
    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .get(environment.API_END_POINT + Url, { params: params, withCredentials: true })
        .pipe(retryWhen(errors => errors
          .pipe(
            switchMap((error) => {
              if (error.status === 422 || error.status === 417) {
                this.getToken()
                return of(error.status);
              }
              throw error
            }),
            scan(retryCount => retryCount + 1, 0),
            takeWhile(retryCount => retryCount < 2),
            delay(1000),
          )))
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        });
    });
  }

  httpPostWithForm(Url: string, formData: any): Promise<any> {
    formData.append("OS", this.deviceInfo.OS);
    formData.append("OSVersion", this.deviceInfo.OSVersion);
    formData.append("IMEI", this.deviceInfo.IMEI);
    formData.append("IMSI", this.deviceInfo.IMSI);
    formData.append("Manufacturer", this.deviceInfo.manufacturer);
    formData.append("Model", this.deviceInfo.model);
    formData.append("ChannelName", this.deviceInfo.ChannelName);
    formData.append("AppVersion", GV.Version);
    formData.append("Country", Enums.Country.Name);
    formData.append("CountryCode", Enums.Country.Code);
    formData.append("UserType", GV.UserType);
    formData.append("Mobile", GV.Mobile);
    formData.append("CompanyId", GV.CompanyId);
    formData.append("SessionID", GV.SessionId);
    formData.append("Currency", 'INR');
    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .post(environment.API_END_POINT + Url, formData, { withCredentials: true })
        .pipe(retryWhen(errors => errors
          .pipe(
            switchMap((error) => {
              if (error.status === 422 || error.status === 417) {
                this.getToken()
                return of(error.status);
              }
              throw error
            }),
            scan(retryCount => retryCount + 1, 0),
            takeWhile(retryCount => retryCount < 2),
            delay(1000),
          )))
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        });
    });
  }

  httpToken(Url: string, body: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const observable = this.httpClient
        .get(environment.API_END_POINT, { withCredentials: true })
      lastValueFrom(observable)
        .then((res) => {
          resolve({ data: JSON.stringify(res) });
        });
    });
  }

  /*    API Calls     */

  generateOtpAPI(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.GenerateOtp, body);
  }

  getAccountDetailsAPI(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.ViewAccountInfo, body);
  }
  Login(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.Login, body);
  }
  Register(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.Register, body);
  }

  /* Report API */

  getToken() {
    this.httpToken("", {});
  }
  AddRefer(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.AddRefer, body)
  }
  GetCredentials(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.GetCredentials, body)
  }
  GenerateCredentials(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.GenerateCredentials, body)
  }
  getConfigAPI(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.GetConfig, body);
  }

  getReportAPI(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.GetReport, body);
  }

  logoutAPI(body: any): Promise<any> {
    return this.httpPost(Enums.URLS.Logout, body);
  }

}
