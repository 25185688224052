import { Component, Input } from '@angular/core';
import { GV } from 'src/app/variables';
import { UtilityService } from 'src/app/_services/utility.service';


@Component({
  selector: 'hb-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent {

  os: string = GV.OS;
  @Input() name: string;
  @Input() fromModal: boolean = false;
  constructor(
    public utilityService: UtilityService
  ) { }
}
