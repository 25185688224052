import { Component } from '@angular/core';
import { Platform, NavController, MenuController, LoadingController, } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { Enums } from './enums';
import { NavigationExtras, Router, Event, NavigationEnd } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { UtilityService } from './_services/utility.service';
import { GV } from "src/app/variables";
import { FcmService } from "src/app/_services/fcm.service";
import { LSService } from './_services/LS.service';
import { routes } from './app-routing.module';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { Meta, Title } from '@angular/platform-browser';
import { Events } from './_services/events';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  showMenu: boolean = false;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    public menuCtrl: MenuController,
    public loadingCtrl: LoadingController,
    private apiService: ApiService,
    public utilityService: UtilityService,
    private fcmService: FcmService,
    private lsService: LSService,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private event: Events
  ) {
    this.event.subscribe('reloadMainMenu', () => {
      this.showMenu = true
    })
    this.event.subscribe('hideMenu', () => {
      this.showMenu = false
    })
    this.platform.ready().then(() => {
      // this.apiService.getToken()

      this.utilityService.checkPlatform();

      // this.router.events.subscribe((event: Event) => {
      //   if (event instanceof NavigationEnd) {
      //     utilityService.checkMenu();
      //     for (let i = 0; i < routes?.length; i++) {
      //       if (routes[i].path?.includes((this.router.url).replace("/", ""))) {
      //         if (routes[i]?.data?.['title']) {
      //           this.title.setTitle(routes[i]?.data?.['title']);
      //         } else {
      //           this.title.setTitle("Best Integrated Cash Flow and Credit Solution App in India");
      //         }
      //         if (routes[i]?.data?.['description']) {
      //           this.meta.updateTag({ name: 'description', content: routes[i]?.data?.['description'] });
      //         } else {
      //           this.meta.updateTag({ name: 'description', content: "Take control of your business finances with the best-integrated cash flow and credit solution app. Optimize your financial management today! Get started now." });
      //         }
      //       }
      //     }
      //   }
      // })
      let url = window.location.href.replace('/?', '?')
      let urlTemp = url.split('/')
      if (urlTemp.length > 3) {
        if (urlTemp[3].includes("verifyCustomer") && urlTemp[3].includes("id=")) {
          this.navCtrl.navigateRoot('/verifyCustomer/' + window.location.search)
          return
        }
        if (urlTemp[3] !== "") {
          lsService.getSessLS();
          let URL = urlTemp[3]
          if (urlTemp[4] !== undefined && urlTemp[4] !== "") {
            URL = URL + "/" + urlTemp[4]
          }
          for (let i = 0; i < routes?.length; i++) {
            if (routes[i].path?.includes(URL)) {
              if (GV.Email !== "" && GV.Email !== undefined && GV.SessionId !== "" && GV.SessionId !== undefined && GV.CompanyId !== "" && GV.CompanyId !== undefined) {
                if (!routes[i]?.data?.['refresh']) {
                  this.navCtrl.navigateRoot("/home")
                  return
                }
                let navigationExtras: NavigationExtras = {
                  state: {
                    showHome: URL !== 'home' && URL !== 'txnOtp' ? false : true
                  }
                }
                if (routes[i].data?.['redirect'] !== '' && routes[i].data?.['redirect'] !== undefined) {
                  if (routes[i].data?.['redirect'] !== '/home') {
                    utilityService.isReloaded = true
                    this.navCtrl.navigateRoot('/loading')
                  } else {
                    this.navCtrl.navigateRoot('/home', navigationExtras)
                  }
                  this.navCtrl.navigateForward(routes[i].data?.['redirect'])
                  return
                }
                let path = ""
                for (let i = 0; i < urlTemp.length; i++) {
                  if (i >= 3) {
                    path = path + '/' + urlTemp[i]
                  }
                }
                if (path !== '/home') {
                  utilityService.isReloaded = true;
                  this.navCtrl.navigateRoot('/loading')
                } else {
                  this.navCtrl.navigateRoot('/home', navigationExtras)
                }
                return
              }
            }
            // else if (routes[i].path.includes(urlTemp[3] + '/:service') || urlTemp[3] === 'loading') {
            //
            // }
          }
        }
      }
      if (GV.Email !== "" && GV.Email !== undefined && GV.SessionId !== "" && GV.SessionId !== undefined && GV.CompanyId !== "" && GV.CompanyId !== undefined) {
        this.navCtrl.navigateRoot("/home")
        return
      }
      this.lsService.clearLS()
      this.navCtrl.navigateRoot('/login');
      this.event.publish("reloadSideMenu");
    });
  }

  initializeApp() {
    this.navCtrl.navigateRoot('/login')
  }


  async setUserForCrash() {
    await FirebaseCrashlytics.setUserId({
      userId: GV.Email
    });
  }

}
