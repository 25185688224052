import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OTPGuard, InitGuard, AuthGuard } from "src/app/_helpers/auth.guard";
import { CustomPreloadingStrategyService } from './_services/custom-preloading-strategy.service';

export const routes: Routes = [
  {
    path: 'login',
    data: { preload: true, serviceCode: "", onBoard: true, refresh: false, redirect: '' },
    loadChildren: () => import("./userAuthentication/userLogin/userLogin.module").then((m) => m.UserLoginPageModule),
    canActivate: [InitGuard],
  },
  {
    path: 'verifyOtp',
    data: { preload: true, serviceCode: "", onBoard: true, refresh: false, redirect: '' },
    loadChildren: () => import("./common/otp/otp.module").then((m) => m.OtpPageModule),
    canActivate: [OTPGuard],
  },
  {
    path: 'loading',
    loadChildren: () => import('./loading/loading.module').then(m => m.LoadingPageModule)
  },
  {
    path: 'signUp',
    loadChildren: () => import('./userAuthentication/create-account/create-account.module').then(m => m.CreateAccountPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'userList',
    loadChildren: () => import('./user-list/user-list.module').then(m => m.UserListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'compliance/:service',
    loadChildren: () => import('./compliance/compliance.module').then(m => m.CompliancePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'userDashboard/:service',
    loadChildren: () => import('./user-dashboard/user-dashboard.module').then(m => m.UserDashboardPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-details',
    loadChildren: () => import('./addCustomer/customer-details/customer-details.module').then(m => m.CustomerDetailsPageModule)
  },
  {
    path: 'select-package',
    loadChildren: () => import('./addCustomer/select-package/select-package.module').then(m => m.SelectPackagePageModule)
  },
  {
    path: 'verifyCustomer/:service',
    loadChildren: () => import('./userAuthentication/verify-customer/verify-customer.module').then(m => m.VerifyCustomerPageModule)
  },
  {
    path: 'api-docs',
    loadChildren: () => import('./api-docs/api-docs.module').then(m => m.ApiDocsPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
