import { Injectable } from "@angular/core";
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

@Injectable({
    providedIn: 'root'
})

export class MobileNativeSettings {

    constructor() { }

    Open() {
        NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App
        })
    }
}