import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';
import { Enums } from 'src/app/enums';

@Component({
  selector: 'hb-dtfooter',
  templateUrl: './dtfooter.component.html',
  styleUrls: ['./dtfooter.component.scss'],
})
export class DtfooterComponent implements OnInit {

  itemsPerPage: number = Enums.ITEMS_PER_PAGE

  @Input() page: number;
  @Input() objLength: number;
  @Input() totalPages: number;
  @Input() fromPage: number;
  @Input() toPage: number;

  @Output() prevPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() nextPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() rootPage: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public utilityService: UtilityService
  ) { }

  ngOnInit() { }

  moveToPrevPage(numberOfPages) {
    this.prevPage.emit(numberOfPages)
  }

  moveToNextPage(numberOfPages) {
    this.nextPage.emit(numberOfPages)
  }
  moveToRootPage() {
    this.rootPage.emit()
  }

}
