import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BackButtonComponent } from "src/app/_components/back-button/back-button.component";
import { TitleComponent } from "src/app/_components/title/title.component";
import { InlineErrComponent } from "src/app/_components/inline-err/inline-err.component";
import { InputLabelComponent } from "src/app/_components/input-label/input-label.component";
import { ContentSegmentComponent } from "src/app/_components/content-segment/content-segment.component";
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HeaderComponent } from '../_components/header/header.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DtfooterComponent } from '../_components/dtfooter/dtfooter.component';
import { SideMenuComponent } from '../_components/side-menu/side-menu.component';
import { ProfileComponent } from '../_components/profile/profile.component';
import { ShortNumberPipe } from 'src/app/_pipes/short-number.pipe';
import { PopoverComponent } from '../_components/popover/popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NgxQRCodeModule,
    MatTooltipModule,
  ],
  declarations: [
    ShortNumberPipe,
    BackButtonComponent,
    TitleComponent,
    InlineErrComponent,
    InputLabelComponent,
    ContentSegmentComponent,
    HeaderComponent,
    DtfooterComponent,
    SideMenuComponent,
    ProfileComponent,
    PopoverComponent
  ],
  exports: [
    ShortNumberPipe,
    BackButtonComponent,
    TitleComponent,
    InlineErrComponent,
    InputLabelComponent,
    ContentSegmentComponent,
    HeaderComponent,
    HeaderComponent,
    DtfooterComponent,
    SideMenuComponent,
    ProfileComponent,
    PopoverComponent
  ]
})
export class SharedModule { }
