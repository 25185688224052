import { Component, Input } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'hb-input-err',
  templateUrl: './inline-err.component.html',
  styleUrls: ['./inline-err.component.scss'],
})
export class InlineErrComponent {

  @Input() msg: string;
  @Input() isSelect: string;

  defaultMsg = "Please enter a valid value"
  defaultSelectMsg = "Please select a valid value"
  constructor(
    public utilityService: UtilityService
  ) { }
}
