import { Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';
import { Enums } from 'src/app/enums';
import { GV } from 'src/app/variables';
import { ApiService } from 'src/app/_services/api.service';
import { LSService } from 'src/app/_services/LS.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
// import { SwitchBusinessPage } from '../../_modals/switch-business/switch-business.page';

@Component({
  selector: 'hb-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  wasInside: boolean = false;
  @Input() styles: any = {};
  @Output() onProfileClose = new EventEmitter<any>();
  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }
  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.dismiss()
    }
    this.wasInside = false;
  }
  topupBalanceObj: any = {
    SMSCount: "0",
    WhatsAppCount: "0",
  }
  company_logo: string = "";
  companyName: string = "";
  fname: string = "";
  mobile: string = "";
  email: string = '';

  constructor(
    public utilityService: UtilityService,
    private navCtrl: NavController,
    public loadingCtrl: LoadingController,
    private apiService: ApiService,
    public lsService: LSService,
    public modalController: ModalController,

  ) {
    this.fname = GV.FirstName
    this.company_logo = GV.Logo;
    this.companyName = GV.CompanyName;
    this.mobile = GV.Mobile;
    // this.topupBalanceObj.SMSCount = GV.TopupBalanceObj.SMSCount
    // this.topupBalanceObj.WhatsAppCount = GV.TopupBalanceObj.WhatsAppCount
    this.email = GV.Email
  }

  ngOnInit() {
    this.getTopupReport()
    this.getAccountDetails()
  }

  getBizInfo() {
    this.dismiss()
    this.navCtrl.navigateForward("/settings/getBizInfo")
  }

  addNewAccount() {
    this.dismiss()
    // this.utilityService.routeFrom = 'menu'
    this.navCtrl.navigateForward('/settings/addCompany')
  }

  dismiss() {
    this.onProfileClose.emit({})
  }

  addCredit() {
    this.dismiss()
    // this.utilityService.topupFrom = ''
    // this.utilityService.bCreditsType = 'Message'
    this.navCtrl.navigateForward('/settings/buyCredits')
  }

  async switchBusiness() {
    // const modal = await this.modalController.create({
    //   component: SwitchBusinessPage,
    //   componentProps: {
    //   },
    //   swipeToClose: true,
    //   backdropDismiss: true,
    // });
    // return await modal.present();
  }

  async getTopupReport() {
    let reqBody = {
      ReportName: "topup_balance",
    }
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      this.apiService.getReportAPI(reqBody)
        .then(data => {
          const response = JSON.parse(data.data)
          if (response.Code.toString() !== "0") {
            ld.dismiss();
            this.utilityService.showErrMsgTop(response.Message);
            if (response.Code.toString() === "1") {
              this.utilityService.clearSessionStorageData();
            }
            return;
          }

          if (response.Row.length > 0) {
            for (let col of response.Row[0].Cols) {
              let tmp = col.Value.toString()
              switch (col.Key.toString()) {
                case "sms_count":
                  this.topupBalanceObj.SMSCount = tmp === '' ? 0 : (+tmp);
                  // GV.TopupBalanceObj.SMSCount = this.topupBalanceObj.SMSCount
                  break;
                case "whatsapp_count":
                  this.topupBalanceObj.WhatsAppCount = tmp === '' ? 0 : (+tmp);
                  // GV.TopupBalanceObj.WhatsAppCount = this.topupBalanceObj.WhatsAppCount
                  break;
              }
            }
          }
          ld.dismiss();
        }, err => {
          ld.dismiss();
        });
    });
  }

  async getAccountDetails() {
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      this.apiService.getAccountDetailsAPI({})
        .then(data => {
          const response = JSON.parse(data.data)
          ld.dismiss();
          if (response.Code.toString() !== "0") {
            if (response.Code.toString() === "1") {
              this.utilityService.showErrMsgTop(response.Message);
              this.utilityService.clearSessionStorageData();
            }
            return;
          }
          this.company_logo = response.hasOwnProperty("Business") ? response.Business.Logo : "";
          this.companyName = response.hasOwnProperty("Business") ? response.Business.Name : "";
          this.email = response.hasOwnProperty("User") ? response.User.Email : "";
          // this.utilityService.storeProfileInfoNS(response);
          this.fname = GV.FirstName
        }, err => {
          ld.dismiss();
        });
    });
  }

}
