import { Component, Input } from '@angular/core';
import { PopoverController, NavController, ModalController } from '@ionic/angular';
import { UtilityService } from 'src/app/_services/utility.service';
import { GV } from 'src/app/variables';
import { Events } from 'src/app/_services/events';
import { PopoverComponent } from '../popover/popover.component';
import { SupportPage } from 'src/app/_modals/support/support.page';

@Component({
  selector: 'hb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  showPopOverOption = false
  fname: string = "";
  mobile: string = "";
  email: string = '';

  @Input() companyName: string = ""
  @Input() company_logo: string = ""

  constructor(
    private event: Events,
    public utilityService: UtilityService,
    public popOverCtrl: PopoverController,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
  ) {
    this.fname = GV.FirstName
    this.mobile = GV.Mobile
    this.email = GV.Email
    this.event.subscribe('reloadHeader', () => {
      this.fname = GV.FirstName
      this.mobile = GV.Mobile
      this.email = GV.Email
      this.companyName = GV.CompanyName
    })
  }

  clickedProfile() {

  }
  onProfileClose() {

  }

  async notificationPopOver(ev: any) {
    this.showPopOverOption = true;
    const notification = await this.popOverCtrl.create({
      component: PopoverComponent,
      componentProps: {
      },
      cssClass: 'notification-popover',
      event: ev,
      mode: 'ios',
    });
    notification.onWillDismiss().then(data => {
      this.showPopOverOption = false;
      if (data !== undefined && data !== null && data.data !== null && data.data !== undefined) {
        if (data.data.option !== "" && data.data.option !== undefined) {

        }
      }
    })
    return await notification.present();
  }

  settings() {
    this.navCtrl.navigateForward('/settings')
  }

  async support() {
    const modal = await this.modalCtrl.create({
      component: SupportPage,
      // componentProps: {
      //   deniedAlways: this.deniedAlways
      // },
    });
    // modal.onDidDismiss().then(data => {
    //   if (data.data !== '' && data.data !== undefined) {
    //     if (data.data === 'accepted') {
    //       this.loginForm.patchValue({
    //         consent: true
    //       })
    //     }
    //   }
    // })
    return await modal.present();
  }
}
