import { Injectable } from '@angular/core';
import { ToastController, Platform, AlertController, NavController, LoadingController, MenuController, ModalController } from '@ionic/angular';
import { Enums } from "src/app/enums";
import { Network } from '@capacitor/network';
import { ApiService } from 'src/app/_services/api.service';
import { Events } from 'src/app/_services/events';
import { GV } from "src/app/variables";
import * as XLSX from 'xlsx';
import { Preferences } from '@capacitor/preferences';
import { Contacts } from '@capacitor-community/contacts';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { saveAs } from 'file-saver';
import { File } from '@ionic-native/file';
import { FilePermissionPage } from '../_modals/file-permission/file-permission.page';
declare var window: Window & typeof globalThis;

@Injectable({
  providedIn: "root",
})
export class UtilityService {

  IsWebChannel = false;
  mobile: string = "";
  sessId: string = "";
  companyId: string = "";
  countryCode: string = "+91";
  CountryCurrencyValue: string = "INR"
  deviceContacts = [];
  invoiceType = "";
  isAppPause = false;
  hyloTxnNo: string = "";
  IsReferralPartner: boolean = false;
  apiList = [];


  countryName: string = Enums.Country.Name;
  mobileMaxLength: string = Enums.Country.MobileMaxLength;
  bgMode: string = "";


  txnOTPdetails: any = {};
  txnRouter: string = "";
  userMenuList = [];


  staticPaymentLink: string = "";
  erp: string = "";
  activeMenu: string = "";
  isE_invoice: boolean = false;

  showStockDesc = false;

  tapNotification = false;
  setLang: any = {
    Language: "",
    LanguageCode: "",
    Description: "",
    Country: "",
    CountryFlag: ""
  }

  mainMenu: string = "";
  subMenu: string = "";
  tmpmenu: string = ""
  companyName: string;
  theodoreFor = ""
  pgStatus: string = '';
  isReloaded: boolean = false;
  updateContactFlag: boolean = false;
  deniedAlways: boolean = false;
  constructor(
    private toastCtrl: ToastController,
    private platform: Platform,
    public alertCtrl: AlertController,
    private navCtrl: NavController,
    private apiService: ApiService,
    public loadingCtrl: LoadingController,
    private event: Events,
    public menuCtrl: MenuController,
    private navController: NavController,
    private diagnostic: Diagnostic,
    private modalCtrl: ModalController,
  ) {
  }
  isCapacitor() {
    if (this.platform.is('capacitor')) {
      GV.IsWebChannel = false;
      return true;
    } else {
      GV.IsWebChannel = true;
      return false;
    }
  }

  isServiceAllow(req_service): Boolean {
    if (GV.SERVICES.length === 0) {
      return false
    }
    let index = GV.SERVICES.findIndex((service) => {
      return req_service === service.Code;
    });
    return index === -1 ? false : true
  }

  getServiceDeatial(req_service): number {
    let index = GV.SERVICES.findIndex((service) => {
      return req_service === service.Code;
    });
    return index;
  }

  /* Store login response into native storage*/
  storeLoginResponseNS(responseObj: any, mobile: string) {
    if (this.isCordova()) {
      Preferences.set({ key: Enums.NSKeys.Mobile, value: mobile })
      Preferences.set({ key: Enums.NSKeys.SessionID, value: responseObj.SessionID })
      Preferences.set({ key: Enums.NSKeys.CompanyId, value: responseObj.hasOwnProperty("Business") ? responseObj.Business.Id : "" })
    }
    GV.Mobile = mobile;
    GV.SessionId = responseObj.SessionID;
    GV.FirstName = responseObj.hasOwnProperty("User") ? responseObj.User.FirstName : "";
    GV.LastName = responseObj.hasOwnProperty("User") ? responseObj.User.LastName : "";
    GV.CompanyId = responseObj.hasOwnProperty("Business") ? responseObj.Business.Id : "";
  }

  checkPlatform() {
    if (this.platform.is("android")) {
      GV.OS = "android";
      GV.Version = Enums.APP_INFO.ANDROID_VERSION;
    } else if (this.platform.is("ios")) {
      GV.OS = "ios";
      GV.Version = Enums.APP_INFO.IOS_VERSION;
    } else {
      GV.OS = "browser";
      GV.Version = Enums.APP_INFO.WEB_VERSION;
    }
  }

  isCordova() {
    if (this.platform.is("electron") || this.platform.is("desktop")) {
      this.IsWebChannel = true;
      GV.IsWebChannel = true;
      return false;
    } else if (this.platform.is("capacitor")) {
      this.IsWebChannel = false;
      GV.IsWebChannel = false;
      return true;
    } else {
      this.IsWebChannel = true;
      GV.IsWebChannel = true;
      return false;
    }
  }

  isMobileWeb() {
    return this.platform.is("mobileweb");
  }

  clearSession() {
    GV.Mobile = "";
    GV.SessionId = "";
    GV.CompanyId = "";
    this.apiService.sessInfo.Mobile = "";
    this.apiService.sessInfo.CompanyId = "";
    this.apiService.sessInfo.SessionID = "";
  }

  chargeCalculation(cVal, priceFloat) {
    if (cVal.includes("%")) {
      let valArr = cVal.split("%");
      let valFloat = parseFloat(valArr[0]);
      return isNaN(valFloat) ? 0 : (valFloat / 100) * priceFloat;
    } else {
      let valFloat = parseFloat(cVal);
      return isNaN(valFloat) ? 0 : valFloat;
    }
  }

  maskEmailId(email): string {
    let tmp = email.split('@')
    if (tmp.length === 2) {
      let tmp2 = tmp[1].split('.')
      email = tmp[0][0] + '*'.repeat(tmp[0].length - 1) + '@' + tmp2[0][0] + '*'.repeat(tmp2[0].length - 1)
      tmp2.splice(0, 1)
      email = email + '.' + tmp2.join('.')
    }
    return email
  }

  /* Validate Session by fetching values from native storage */
  async validateSession() {
    return Promise.all([
      Preferences.get({ key: Enums.NSKeys.Mobile }),
      Preferences.get({ key: Enums.NSKeys.SessionID }),
    ])
      .then((data) => {
        this.mobile = data[0].value === null ? '' : data[0].value;
        this.sessId = data[1].value === null ? '' : data[1].value;
        if (this.mobile === "" || this.mobile === undefined || this.sessId === "" || this.sessId === undefined) {
          return false
        }
        return true
      })
      .catch((error) => {
        return false;
      });
  }
  //  Network Inteface plugin helps to Identify the Internet Connectivety
  checkConnectivity() {
    Network.addListener('networkStatusChange', status => {
      if (status.connected === false) {
        this.showErrMsgTop("It seems some issue with your internet connection.");
      }
    });
  }

  async clearSessionStorageData() {
    Enums.PhoneBookContacts = [];
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      if (this.isCordova()) {
        Promise.all([
          Preferences.get({ key: Enums.NSKeys.Mobile }),
          Preferences.get({ key: Enums.NSKeys.SessionID }),
          Preferences.get({ key: Enums.NSKeys.BgMode })
        ])
          .then((data) => {
            this.mobile = data[0].value === null ? '' : data[0].value;
            this.sessId = data[1].value === null ? '' : data[1].value;
            this.bgMode = data[2].value === null ? '' : data[2].value;

            Preferences.clear()
              .then(data => {
                Preferences.set({ key: Enums.NSKeys.BgMode, value: GV.bgMode })
                ld.dismiss();
                this.resetGVVariables();
                this.navCtrl.navigateRoot('/login')
              })
              .catch(error => {
                ld.dismiss();
              });
          })
          .catch((err) => {
            ld.dismiss();
          });
      } else {
        ld.dismiss();
        localStorage.clear()
        window.location.reload();
      }
    });
  }

  /* Toast function -  showErrMsgTop()
      Description - Shows error message on top of the screen
      */
  async showErrMsgTop(msg: string, flag?: boolean) {

    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 10000,
      header: "",
      position: "top",
      cssClass: "custom-toast-message fw-bold",
      color: "danger",
      buttons: [
        {
          text: "OK",
          cssClass: "fw-bold",
          role: "cancel",
          handler: () => { },
        },
      ],
    });
    if (flag === true) {
      toast.header = "Error messages"
    }
    toast.present();
  }

  /* Toast function -  showSuccessMsgTop()
    Description - Shows success message on top of the screen
    */
  async showSuccessMsgTop(msg: string) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 10000,
      position: "top",
      cssClass: "custom-toast-message fw-bold",
      color: "success",
      buttons: [
        {
          text: "OK",
          cssClass: "fw-bold",
          role: "cancel",
          handler: () => { },
        },
      ],
    });
    toast.present();
  }

  /* Toast function -  showNotificationMsgTop()
    Description - Shows notification message on top of the screen
    */
  async showNotificationMsgTop(notification) {
    let toast = await this.toastCtrl.create({
      message: notification.body,
      duration: 10000,
      position: "top",
      cssClass: "fw-bold",
      buttons: [
        {
          text: "OK",
          cssClass: "fw-bold",
          handler: () => {
            this.navController.navigateForward(notification.data.route);
          },
        },
      ],
    });
    toast.present();
  }

  copyToClipboardStaticLink(from, staticPaymentLink) {
    const elem = document.createElement("textarea");
    elem.value =
      from +
      " has requested a payment. To complete the payment, click on the link - " +
      staticPaymentLink;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    this.showSuccessMsgTop("Copied to clipboard.");
  }

  staticOpenNewWindow(urlString) {
    if (window.self !== window.top) {
      window.open(urlString, "_blank");
    } else {
      window.open(urlString, "_self");
    }
  }

  readXlsxFile(event: any): any {
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      return;
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 }); // to get 2d array pass 2nd parameter as object {header: 1}
      return data[0] as any[];
    };
  }

  highlightMenu(menuName) {
    this.activeMenu = menuName;
    this.event.publish("highlightMenu");
  }

  selectedServices(): Array<any> {
    let services = [];
    for (let menu of this.userMenuList) {
      if (menu.hasOwnProperty("SubMenus")) {
        for (let sub of menu.SubMenus) {
          if (sub.DefaultEnabled === true) {
            services.push(sub.Id);
          }
        }
      } else {
        if (menu.DefaultEnabled === true) {
          services.push(menu.Id);
        }
      }
    }
    return services;
  }

  updateCntReq(contactId, response): any {
    this.updateContactFlag = true;
    if (response.Mobile !== '' && response.MobileCountry === '') {
      response.MobileCountry = Enums.Country.Name
    }
    return {
      ContactId: contactId,
      ContactMobile: response.Mobile,
      ContactEmail: response.Email,
      ContactEmails: response.Emails,
      ContactFullName: response.Name,
      ContactBizName: response.CompanyName,
      MobileCountry: response.MobileCountry,
      Notes: response.Notes,
      OpeningBalance: response.OpeningBalance,
      ContactType: response.Type,
      InventoryFlag: response.InventoryFlag,
      TnC: response.TnC,
      TRNNo: response.TRNNo,
      TLNo: response.TLNo,
      GSTNo: response.GSTNo,
      PANNo: response.PANNo,
      Addresses: response.Addresses,
      ReminderId: response.ReminderId,
      ReminderType: response.ReminderType,
      ReminderFlag: response.ReminderFlag,
      ReminderName: response.ReminderName,
      BizCountry: response.BizCountry,
      ContactCompanyId: response.CompanyId
    }
  }

  resetGVVariables() {
    for (const prop in GV) {
      if (typeof GV[prop] === 'string' && prop !== 'MaxTxnAmt' && prop !== 'bgMode' && prop !== 'TutorialsLink') {
        GV[prop] = ""
      }
      if (typeof GV[prop] === 'boolean') {
        GV[prop] = false
      }
      if (typeof GV[prop] === 'object' && prop !== 'AppPages') {
        if (Array.isArray(GV[prop])) {
          GV[prop] = [];
        } else {
          if (Object.keys(GV[prop]).length > 0) {
            for (const property in GV[prop]) {
              if (typeof GV[prop][property] === 'string') {
                GV[prop][property] = ""
              } else if (typeof GV[prop][property] === 'boolean') {
                GV[prop][property] = false
              }
            }
          } else {
            GV[prop] = {}
          }
        }
      }
    }
  }

  formatCurrency(number, key?, abs?, isNegative?) {
    let formattedNumber;
    let os = GV.OS;
    let numberArr;
    let numberTmp;
    let decimalTmp;
    if (number.toString() !== '0.00' && number !== undefined && number !== null && number !== '') {
      if (number.toString().includes('.')) {
        numberArr = number.toString().split('.');
        numberTmp = numberArr[0]
        decimalTmp = numberArr[1]
      } else {
        numberTmp = number;
      }
      if (numberTmp !== '' && numberTmp !== null && numberTmp !== undefined) {
        formattedNumber = numberTmp.toString().replace(/\D/g, "").replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
        if (GV.IsWebChannel !== true || os == 'android' || os == 'ios') { // for mobile
          if (formattedNumber.length >= 6) {
            formattedNumber = (isNegative ? '-' : '') + abs + key
          } else {
            if (formattedNumber.length < 6 && decimalTmp !== undefined && decimalTmp !== null && decimalTmp !== '') {
              formattedNumber = (isNegative ? '-' : '') + formattedNumber + "." + decimalTmp
            } else {
              formattedNumber = (isNegative ? '-' : '') + formattedNumber + ".00"
            }
          }
        } else { // for web
          if (decimalTmp !== undefined && decimalTmp !== null && decimalTmp !== '') {
            formattedNumber = formattedNumber + '.' + decimalTmp
          } else {
            formattedNumber = formattedNumber + '.00'
          }
        }

      }
    } else {
      formattedNumber = number
    }
    return formattedNumber
  }

  checkMenu() {
    this.tmpmenu = ""
    let url = window.location.href.replace('/?', '?')
    if (url.includes("home")) {
      this.mainMenu = "home"
      this.subMenu = ""
    }
  }

  getPincode(address) {
    return address.match(/\d+\d+\d+\d+\d+\d+/)[0];
  }

  getQueryParams(qParams) {
    let map = new Map<string, string>();
    for (let i = 0; i < qParams.length; i++) {
      let key = qParams[i].split('=')[0].trim();
      let value = decodeURIComponent(qParams[i].split('=')[1]).trim();
      map.set(key, value)
    }
    return map
  }

  pageCalculation(txnsObj) {
    let toPage = 0
    let page = 0
    let fromPage = 0
    let totalPages = 0
    toPage = Math.min(
      (page + 1) * Enums.ITEMS_PER_PAGE,
      txnsObj.length
    );
    fromPage = page * Enums.ITEMS_PER_PAGE;
    totalPages = Math.ceil(
      txnsObj.length / Enums.ITEMS_PER_PAGE
    );
    return { toPage, page, fromPage, totalPages }
  }

  nextPage(val, page, txnsObj) {
    let fromPage = (page + val) * Enums.ITEMS_PER_PAGE;
    let toPage = Math.min(
      (page + val + 1) * Enums.ITEMS_PER_PAGE,
      txnsObj.length
    )
    page = page + val;

    return { fromPage, toPage, page }
  }

  prevPage(val, page, txnsObj) {
    page = page - val;
    let toPage = Math.min(
      (page + val) * Enums.ITEMS_PER_PAGE,
      txnsObj.length
    )
    let fromPage = page * Enums.ITEMS_PER_PAGE;
    return { fromPage, toPage, page }
  }

  downloadExcelReport(txnsObj, os, fileName, time) {
    let storageLocation = "file:///storage/emulated/0/Download";
    var ws = XLSX.utils.json_to_sheet(txnsObj);
    var wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    var buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    var fileExtenction = '.xlsx';
    var data: Blob = new Blob([buffer], { type: fileType });
    if (os === "android" || os === "ios") {
      this.diagnostic.requestRuntimePermission(this.diagnostic.permission.WRITE_EXTERNAL_STORAGE)
        .then((status) => {
          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              File.writeFile(os === 'android' ? storageLocation : File.dataDirectory, fileName + "-" + time + fileExtenction, data, { replace: true })
                .then(() => {
                  let message = ''
                  if (os === 'android') {
                    message = 'Report is exported to your storage/emulated/0/Download'
                  } else {
                    message = 'Report is exported to your File Manager'
                  }
                  this.showSuccessMsgTop(message)
                })
              return
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.showFilePermission();
              return
            default:
              return
          }
        })
    } else {
      saveAs(data, fileName + "-" + time + fileExtenction)
    }
  }

  async showFilePermission() {
    const modal = await this.modalCtrl.create({
      component: FilePermissionPage,
      cssClass: "file-permission-modal",
      componentProps: {
        deniedAlways: this.deniedAlways
      },
    });
    return await modal.present();
  }

  getWeekAndMonthDates(startDate, endDate) {
    startDate = new Date(startDate)
    endDate = new Date(endDate)
    const weeksInMonth = [];
    const datesOfMonth = [];
    const monthWiseDates = [];

    let currentDate = new Date(startDate);
    const endDateComparison = new Date(endDate);
    endDateComparison.setDate(endDateComparison.getDate() + 1);

    while (currentDate < endDateComparison) {
      const weekDates = [];

      for (let day = 0; day < 7; day++) {
        const date = new Date(currentDate);
        if (date >= startDate && date < endDateComparison) {
          weekDates.push(date);
          datesOfMonth.push(date);
          if (date.getDate() === 1) {
            monthWiseDates.push({
              month: date.toLocaleString('en-US', { month: 'long' }),
              dates: [date],
            });
          } else {
            if (monthWiseDates.length === 0) {
              monthWiseDates.push({
                month: date.toLocaleString('en-US', { month: 'long' }),
                dates: [date],
              });
              continue
            }
            monthWiseDates[monthWiseDates.length - 1].dates.push(date);
          }
        } else {
          weekDates.push(null);
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      weeksInMonth.push(weekDates);
    }

    return { weeksInMonth, datesOfMonth, monthWiseDates };
  }

  // Example usage:
  // const startDate = new Date('2023-01-01');
  // const endDate = new Date('2023-03-31');

  getRandomColor() {
    var lettersLight = ["#864AF950", "#6DA4AA50", "#F6B17A50", "#DC84F350", "#E3651D50"];
    const int = window.crypto.getRandomValues(new Uint32Array(1))[0]
    let color = lettersLight[Math.floor((int / 2 ** 32) * lettersLight.length)];
    return color;
  }



}
