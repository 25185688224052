import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { GV } from 'src/app/variables';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetails implements OnInit {
  subscription: any;
  os: string = GV.OS
  pieChart: any;
  chartPaymentModes = [];
  chartSum = [];

  @ViewChild('pieCanvas', { static: false }) private pieCanvas: ElementRef;

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
  ) {
    setTimeout(() => {
      this.showPieChart()
    }, 1000);

  }

  ngOnInit() { }

  goBack() {
    this.modalCtrl.dismiss();
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribeWithPriority(10000, () => {
      this.goBack();
    })
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }
  showPieChart() {
    if (this.pieChart) {
      this.pieChart.destroy();
    }

    this.chartPaymentModes = ["Total credits", "Total credits used", "Remaining credits"]
    this.chartSum = ["100", "80", "1"];

    this.pieChart = new Chart(this.pieCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.chartPaymentModes,
        datasets: [{
          label: ' ',
          data: this.chartSum,
          backgroundColor: [
            '#37306B',
            '#66347F',
            '#9E4784',
            '#D27685',
            '#4E31AA',
            '#3B185F',
          ],
          borderColor: 'transparent',
          spacing: 0,
          hoverOffset: 4,
          borderRadius: 0
        }]
      },
      options: {
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        animation: {
          duration: 3000
        },
        scales: {
          y: {
            display: false,
            beginAtZero: false
          },
        },
        plugins: {
          legend: {
            display: true,
            position: this.os == 'browser' ? 'right' : 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded'
            }
          }
        }
      }
    });
  }

}
