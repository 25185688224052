export const GV = {
  // String Variables
  CountryCurrency: "₹ ",
  Mobile: "",
  SessionId: "",
  OS: "",
  Version: "",
  FirstName: "",
  LastName: "",
  CompanyId: "",
  UserId: "",
  UserType: "",
  CheckerStatus: "",
  Email: "",
  VerifyOTPInfo: {
    Mobile: "",
    Password: "",
    Country: "",
    CountryCode: "",
    ServiceName: "",
  },
  TxnNo: "",
  BankInfoStatus: "",
  KycInfoStatus: "",
  BusinessInfoStatus: "",
  KycStatus: "",
  KycMsgFlag: "",
  CompanyName: "",
  CompanyEmail: "",
  Logo: "",
  ProfileCompleteness: "",
  Address1: "",
  Address2: "",
  City: "",
  State: "",
  Country: "",
  PayUpdateTxnRedirectPage: "",
  PGStatus: "",
  BusinessType: "",
  APIVersion: '',
  BusinessCategory: "",
  LedgerContactId: "",
  PaymentTxnNo: "",
  bgMode: "nil",
  KYCDocumentNumber: "",
  KYCDocumentType: "",
  TxnSeriesEnabled: "",
  // Boolean Variables
  IsLoggedIn: false,

  // Array Objects
  SERVICES: [],
  Notifications: [],
  showQuickBooksMenu: false,
  showZohoBooksMenu: false,
  showZohoInvoiceMenu: false,

  // Reload Screens Variables
  IsWebChannel: false,
  language: {
    en: {
    }
  },
  AppPages: [
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/home",
      service: "0HD1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: false,
      title: "",
      subTitle: "",
      url: "/settings/tallyDashboard",
      service: "0TDD1",
      cssClass: "dashboard-tally-erp-icon",
      imgPath: "",
    },
    {
      show: false,
      title: "",
      subTitle: "",
      url: "/erp/quickbooks",
      service: "0QBD1",
      cssClass: "dashboard-tally-erp-icon",
      imgPath: "",
    },
    {
      show: false,
      title: "",
      subTitle: "",
      url: "/erp/zohobooks",
      service: "0ZBD1",
      cssClass: "dashboard-tally-erp-icon",
      imgPath: "",
    },
    {
      show: false,
      title: "",
      subTitle: "",
      url: "/erp/zohoinvoice",
      service: "0ZID1",
      cssClass: "dashboard-tally-erp-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/receivables/dashboard",
      service: "0RCVD1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/payables/dashboard",
      service: "0PAYD1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/ledger/list",
      service: "0HLD1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/financing",
      service: "0FHMP1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/stocks/list",
      service: "0SHSL1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/purchaseOrders/txns",
      service: "0POPOL1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/ledger/creditNoteTxns",
      service: "0CND1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/reports",
      service: "0BRRL1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/apiMarketplace",
      service: "0APID1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/settings",
      service: "0BSD1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },
    {
      show: true,
      title: "",
      subTitle: "",
      url: "/help/faq",
      service: "0FAQD1",
      cssClass: "dashboard-icon",
      imgPath: "",
    },

  ]
};
