export const Enums = {
  APP_INFO: {
    ANDROID_VERSION: "1.0", //prod upcoming version Android
    IOS_VERSION: "1.0", //prod upcoming version IOS
    WEB_VERSION: "1.0.9", //prod upcoming version for Web
  },
  Country: {
    Name: "India",
    Code: "+91",
    Flag: "assets/images/country/india.png",
    MobileMaxLength: "10",
  },
  AppLockSettings: {
    RELOCKTIME: "60000",
    IS_BACKGROUND_BIOMATRIC_CHECK: true,
    APP_LOCK_REMINDER_DAYS: 7,
    TITLE: "Enter phone screen lock Pattern, PIN, Password or Fingerprint or Face ID",
    DESCRIPTION: "Unlock viccas",
  },
  NSKeys: {
    SessionID: "SessionID",
    Mobile: "Mobile",
    CompanyId: "CompanyId",
    BgMode: "BgMode",
    FAIO: "FAIO",
    FAIODate: "FAIODate",
    AppRateReminderDate: "AppRateReminderDate",
    AppRateNotNowReminderCount: "1",
    AppRateReminderDays: "30",
    Pin: "Pin",
    SalesId: 'SalesId',
    Name: 'Name',
    UserType: 'UserType',
  },

  URLS: {
    /** URL Resource Paths */
    GenerateOtp: "/Api/v1.0/marketplace/otp/generate",
    Logout: "/Api/v1.0/marketplace/logout",
    Login: "/Api/v1.0/marketplace/login",
    Register: "/Api/v1.0/marketplace/register",
    ViewAccountInfo: "/Api/v1.0/ViewAccount",
    GetReport: "/Api/v1.0/marketplace/getReport",
    AddRefer: "/Api/v1.0/marketplace/refer",
    GenerateCredentials: "/Api/v1.0/marketplace/credentials/generate",
    GetCredentials: "/Api/v1.0/marketplace/getCredentials",
    GetConfig: "/AtlasApi/v1.0/getConfig"
  },
  API_TIMEOUT: 120.0,
  loading: {
    duration: 5000,
  },
  LoaderConfig: {
    message: "",
    cssClass: "custom-loading",
    translucent: false,
    dismissOnPageChange: true,
    enableBackdropDismiss: false,
    showBackdrop: true,
    keyboardClose: true,
  },
  Max_Txn_Amount: 2500000,
  PhoneBookContacts: [],
  MSGS: {
    AlreadyCancelled: '<p class="fw-bold fs-14">This transaction is cancelled.</p>',
    PaymentDone: '<p class="fw-bold fs-14">Payment for this transaction has been processed. You may not be able to <strong>Cancel</strong> this transaction.</p>',
    UpdateCancelled: '<p class="fw-bold fs-14">This transaction has been cancelled. You may not be able to <strong>Update</strong> this transaction.</p>',
    UpdatePaid: '<p class="fw-bold fs-14">Payment for this transaction has been marked paid. You may not be able to <strong>Update</strong> this transaction.</p>',
    PoRejected: '<p class="fw-bold fs-14">This transaction is rejected.</p>',
    PoConvertedInvoice: '<p class="fw-bold fs-14">This transaction has been converted to invoice.</p>',
    PoCancelled: '<p class="fw-bold fs-14">This transaction has been cancelled.</p>',
    StaticLinkMsg: " has requested for payment.\r\nTo complete the payment, click on the link - \r\n\r\n",
    PGINACTIVE_CP: "Thank you for initiating the verification process. Your details have been sent to the verification team. Post your successful verification process you will be able to send the Payment link. This transaction will be sent without payment link. Do you want to proceed?",
  },
  DARK_MODE: false,
  Reload: {
    BizContactListPage: "no",
    ContactListPage: false,
  },
  MAX_UPLOAD_DOC: 5,
  IMAGE_SIZE: 10,
  IMAGE_SIZE_TYPE: "MB",
  PDF_SIZE: 10,
  PDF_SIZE_TYPE: "MB",
  EXCEL_SIZE: 10,
  EXCEL_SIZE_TYPE: "MB",
  SUBMENU_COMPO_WIDTH: 250,
  ITEMS_PER_PAGE: 8
};
