import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token, } from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  navigate: any;

  constructor(
    public utilityService: UtilityService,
    private navController: NavController
  ) { }

  init() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else {
        console.log('permission not granted');
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      // alert('Push registration success, token: ' + token.value);
    }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      this.utilityService.showNotificationMsgTop(notification)
    }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      this.navigate = notification.notification.data.route;
      this.utilityService.tapNotification = true;
      this.navController.navigateForward(notification.notification.data.route);
    }
    );
  }

  /* To get the delievered notifications that are not tapped when app is in background or kill mode */
  getDelieveredNotifications() {
    PushNotifications.getDeliveredNotifications()
  }

  cleanMessages() {
    PushNotifications.removeAllDeliveredNotifications()
  }

  subscribeToTopic(topic) {
    FCM.subscribeTo({ topic: topic }).then(data => {
    })
  }
  unsubscribeFromTopic(topic) {
    FCM.unsubscribeFrom({ topic: topic }).then(data => {
    })
  }

}
