import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Events } from 'src/app/_services/events';
import { GV } from "src/app/variables";
import { UtilityService } from 'src/app/_services/utility.service';
import { Enums } from 'src/app/enums';
import { MixpanelService } from "src/app/_services/mixpanel.service";
import { ApiService } from 'src/app/_services/api.service';
import { LSService } from 'src/app/_services/LS.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onMenuClose: EventEmitter<any> = new EventEmitter<any>();
  showComplianceAPIs = false;
  showVerificationAPIs = false;
  showOtherServices: boolean = false;
  public appPages = GV.AppPages;
  apiList: Array<any> = [];
  categoryList: Array<any> = [];
  selectedCategory = {};
  categoryIndex = 0;
  openCategory = false;

  constructor(
    private event: Events,
    public utilityService: UtilityService,
    private mixpanelService: MixpanelService,
    private apiService: ApiService,
    public loadingCtrl: LoadingController,
    private lsService: LSService,
    private navCtrl: NavController,
  ) {
    this.utilityService.mainMenu = "home"
    const checkApiList = setInterval(() => {
      if (this.utilityService.apiList) {
        this.apiList = utilityService.apiList;
        this.fetchCategory()
        clearInterval(checkApiList)
      }
    }, 1000)
  }

  ngOnInit() {

  }

  async signOut() {
    Enums.PhoneBookContacts = [];
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      // this.mixpanelService.logoutSuccess();
      ld.dismiss();
      this.apiService.logoutAPI({})
        .then(data => {
          this.lsService.clearLS()
          window.location.reload();
        }, err => {
        });
    });
    // this.navCtrl.navigateRoot('/login')
  }

  tutorials() {
    // window.open(GV.TutorialsLink, '_blank')
  }

  route(link) {
    this.utilityService.subMenu = ""
    this.onMenuClose.emit({ link: null })
    if (link === '/home') {
      this.utilityService.mainMenu = "home"
      this.navCtrl.navigateRoot(link)
    } else {
      this.navCtrl.navigateForward(link)
    }
  }
  openUserList() {
    this.utilityService.subMenu = ""
    this.utilityService.mainMenu = "userList"
    this.navCtrl.navigateForward('/userList')
  }

  expandComplianceServices() {
    this.showComplianceAPIs = !this.showComplianceAPIs
  }
  expandVerificationServices() {
    this.showVerificationAPIs = !this.showVerificationAPIs
  }

  Verification(api) {
    this.utilityService.mainMenu = "verification1"
    this.utilityService.subMenu = api
    this.navCtrl.navigateForward(`/compliance/${api}`)
  }

  Compliance(api) {
    this.utilityService.mainMenu = "compliance1"
    this.utilityService.subMenu = api
    this.navCtrl.navigateForward(`/compliance/${api}`)
  }

  fetchCategory() {
    for (let categoryItem in this.apiList) {
      let tempCategory = this.categoryList.filter(item => item.category == this.apiList[categoryItem].category)
      if (tempCategory.length === 0) {
        this.categoryList.push(this.apiList[categoryItem])
      }
    }
  }

  selectCategory(index, item) {
    if (this.openCategory == true && this.categoryIndex != index) {
      this.openCategory = false
    }
    this.categoryIndex = index;
    this.selectedCategory = item
    this.openCategory = !this.openCategory
    this.utilityService.mainMenu = item.category
  }

  navigateToApi(apiId) {
    this.navCtrl.navigateForward(`/compliance/${apiId}`)
  }

}
