import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/_services/api.service';
import { CryptoService } from 'src/app/_services/crypto.service';
import { DateFormatService } from 'src/app/_services/date-format.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ValidatorService } from 'src/app/_services/validator.service';
import { Enums } from 'src/app/enums';
import { GV } from 'src/app/variables';

@Component({
  selector: 'app-generate-api-credentials',
  templateUrl: './generate-api-credentials.component.html',
  styleUrls: ['./generate-api-credentials.component.scss'],
})
export class GenerateAPICredentials implements OnInit {
  subscription: any;
  os: string = GV.OS

  createdOn: string = ""
  updatedOn: string = ""
  keyValue: string = "";
  secretValue: string = "";
  secretKeyObj: Array<any> = [];
  id: string = "";
  from: string = "";

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    public utilityService: UtilityService,
    public loadingCtrl: LoadingController,
    private apiService: ApiService,
    private dfService: DateFormatService,
    private crptSr: CryptoService,
    private navParams: NavParams,
    private validatorService: ValidatorService
  ) {
    this.id = this.navParams.get('Id');
    this.from = this.navParams.get('From');
    this.getSecretKeyTokenApi();
  }

  ngOnInit() { }

  goBack() {
    this.modalCtrl.dismiss();
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribeWithPriority(10000, () => {
      this.goBack();
    })
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  copyToClipboard(value, msg) {
    navigator.clipboard.writeText(value)
      .then(() => {
        this.utilityService.showSuccessMsgTop(msg);
      })
  }

  async getSecretKeyTokenApi() {
    let reqBody = {
      RefereeCompanyId: this.id
    }
    if (this.from === "settings") {
      reqBody.RefereeCompanyId = ''
    }
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      this.apiService.GetCredentials(reqBody)
        .then(data => {
          const response = JSON.parse(data.data)
          this.secretKeyObj = []
          ld.dismiss();
          if (response.Code.toString() !== "0") {
            if (response.Code.toString() === "1") {
              this.utilityService.clearSessionStorageData();
            } else {
              this.generateSecretKeyKeyToken()
            }
            return;
          }
          const response1 = JSON.parse(this.crptSr.decrypt(JSON.parse(data.data).data))
          this.secretKeyObj = response1.SecretKeysRows;
          this.keyValue = this.secretKeyObj[0].KeyValue
          this.secretValue = this.secretKeyObj[0].SecretValue
          this.createdOn = this.dfService.getInputDF2(this.secretKeyObj[0].CreateDate)
          this.updatedOn = this.dfService.getInputDF2(this.secretKeyObj[0].LastUpdate)

        }, err => {
          ld.dismiss();
        });
    });
  }

  async generateSecretKeyKeyToken() {
    let reqBody = {
      RefereeCompanyId: this.id
    }
    if (this.from === "settings") {
      reqBody.RefereeCompanyId = ''
    }
    let ld = await this.loadingCtrl.create(Enums.LoaderConfig);
    ld.present().then(() => {
      this.apiService.GenerateCredentials(reqBody)
        .then(data => {
          const response = JSON.parse(data.data)
          ld.dismiss();
          if (response.Code.toString() !== "0") {
            this.utilityService.showErrMsgTop(response.Message)
            return;
          }
          this.utilityService.showSuccessMsgTop(response.Message);
          this.getSecretKeyTokenApi();
        }, err => {
          ld.dismiss();
        });
    });
  }

}
