import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

/* App Modals */

/* Services */
import { ApiService } from 'src/app/_services/api.service';
import { UtilityService } from './_services/utility.service';
import { MixpanelService } from "./_services/mixpanel.service";
import { LSService } from './_services/LS.service';
import { MobileNativeSettings } from "src/app/_services/nativeSettings.service";

/* Components*/
import { ShortNumberPipe } from './_pipes/short-number.pipe';

/** App plugins */
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { DatePicker } from '@ionic-native/date-picker/ngx';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Mixpanel, MixpanelPeople } from '@ionic-native/mixpanel/ngx';
import { NgOtpInputModule } from 'ng-otp-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from './_shared/shared.module';
import { MaskDirective } from './_directives/mask/mask.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AddUserPage } from './_modals/add-user/add-user.page';
import { FilePermissionPage } from './_modals/file-permission/file-permission.page';
import { UserDetails } from './_modals/user-details/user-details.component';
import { GenerateAPICredentials } from './_modals/generate-api-credentials/generate-api-credentials.component';

import { GoogleInitOptions, GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { TermsConditionsPage } from './_modals/terms-conditions/terms-conditions.page';
import { SupportPage } from './_modals/support/support.page';

export function playerFactory() {
  return player;
}

const options: GoogleInitOptions = {
  oneTapEnabled: true,
  scopes: ['profile', 'email'],
  prompt: 'consent',
  prompt_parent_id: 'my-one-tap-container'
};

@NgModule({
  declarations: [
    AppComponent,
    MaskDirective,
    AddUserPage,
    FilePermissionPage,
    UserDetails,
    GenerateAPICredentials,
    TermsConditionsPage,
    SupportPage
  ],
  entryComponents: [
    AppComponent,
    FilePermissionPage,
    UserDetails,
    GenerateAPICredentials
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    AppRoutingModule,
    LottieModule.forRoot({ player: playerFactory }),
    BackButtonDisableModule.forRoot(),
    BrowserAnimationsModule,
    ScrollingModule,
    SharedModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgxQRCodeModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      rippleEffect: true,
      mode: 'ios'
    }),
    SocialLoginModule,
    GoogleSigninButtonModule
  ],
  providers: [
    SocialSharing,
    UtilityService,
    MixpanelService,
    ApiService,
    DatePipe,
    DatePicker,
    Diagnostic,
    Mixpanel,
    MixpanelPeople,
    LSService,
    MobileNativeSettings,
    ShortNumberPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '536844235397-o194hlptoh55h95clq3m90b2k81ok0ck.apps.googleusercontent.com', options
            )
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
