import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { GV } from '../variables';
import { UtilityService } from "src/app/_services/utility.service";
import { Enums } from 'src/app/enums';
import { LSService } from '../_services/LS.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        public utilityService: UtilityService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.utilityService.isCordova()) {
            Promise.all([
                Preferences.get({ key: Enums.NSKeys.Mobile }),
                Preferences.get({ key: Enums.NSKeys.SessionID }),
                Preferences.get({ key: Enums.NSKeys.CompanyId }),
            ]).then(
                data => {
                    let mobile = data[0].value === null ? '' : data[0].value;
                    let sessId = data[1].value === null ? '' : data[1].value;
                    let cmpId = data[2].value === null ? '' : data[2].value;
                    if (mobile === "" || mobile === undefined || sessId === "" || sessId === undefined || cmpId === "" || cmpId === undefined) {
                        this.router.navigate(['/login'])
                        return false
                    }
                    return true
                })
                .catch(err => {
                    this.router.navigate(['/login'])
                    return false
                })
        } else {
            if (GV.Email === undefined || GV.Email === '' || GV.SessionId === undefined || GV.SessionId === '' || GV.CompanyId === undefined || GV.CompanyId === '') {
                this.router.navigate(['/login'])
                return false
            }
        }
        return true
    }
}

@Injectable({ providedIn: 'root' })
export class OTPGuard implements CanActivate {
    constructor(
        private router: Router,
        public utilityService: UtilityService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.router.getCurrentNavigation().extras) {
            return true
        } else if (this.utilityService.txnOTPdetails.Type !== undefined && this.utilityService.txnOTPdetails.Type !== null) {
            return true
        } else {
            this.router.navigate(['/login'])
            return false
        }
    }
}

@Injectable({ providedIn: 'root' })
export class InitGuard implements CanActivate {
    constructor(
        private router: Router,
        public utilityService: UtilityService,
        private lsService: LSService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.utilityService.isCordova()) {
            return Promise.all([
                Preferences.get({ key: Enums.NSKeys.Mobile }),
                Preferences.get({ key: Enums.NSKeys.SessionID }),
                Preferences.get({ key: Enums.NSKeys.CompanyId }),
            ]).then(
                data => {
                    let mobile = data[0].value === null ? '' : data[0].value;
                    let sessId = data[1].value === null ? '' : data[1].value;
                    let cmpId = data[2].value === null ? '' : data[2].value;
                    if (mobile === "" || mobile === undefined || sessId === "" || sessId === undefined || cmpId === "" || cmpId === undefined) {
                        return true
                    } else {
                        this.router.navigate(['/home'])
                        return false
                    }
                })
                .catch(err => {
                    return true
                })
        } else {
            this.lsService.getSessLS()
            if (GV.Mobile === "" || GV.Mobile === undefined || GV.SessionId === "" || GV.SessionId === undefined || GV.CompanyId === "" || GV.CompanyId === undefined) {
                return true
            } else {
                this.router.navigate(['/home'])
                return false
            }
        }
    }
}
