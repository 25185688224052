import { Injectable } from '@angular/core';
import { GV } from '../variables';
import { CryptoService } from './crypto.service';
import { EncryptStorage } from 'encrypt-storage';
import { Preferences } from '@capacitor/preferences';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: "root",
})

export class LSService {

  encryptStorage: any;

  constructor(
    public crptService: CryptoService,
    public utilityService: UtilityService,
  ) {
    this.encryptStorage = new EncryptStorage('U2FsdGVkX1AxbgS', { storageType: 'localStorage' });
  }

  setSessLS(email: string, sessId: string, companyId: string, userType: string, userId: string, fName: string, lName: string, companyName: string) {
    this.encryptStorage.setItem('637829926', email)
    this.encryptStorage.setItem('065356277', sessId)
    this.encryptStorage.setItem('000173637', companyId)
    this.encryptStorage.setItem('000437643', userType)
    this.encryptStorage.setItem('000343432', userId)
    this.encryptStorage.setItem('000343431', fName)
    this.encryptStorage.setItem('000343433', lName)
    this.encryptStorage.setItem('000343434', companyName)
  }

  getSessLS() {
    GV.SessionId = this.encryptStorage.getItem('065356277') || ''
    GV.Email = this.encryptStorage.getItem('637829926') || ''
    GV.CompanyId = JSON.stringify(this.encryptStorage.getItem('000173637')) || ''
    GV.UserType = this.encryptStorage.getItem('000437643') || ''
    GV.UserId = JSON.stringify(this.encryptStorage.getItem('000343432')) || ''
    GV.FirstName = this.encryptStorage.getItem('000343431') || ''
    GV.LastName = this.encryptStorage.getItem('000343433') || ''
    GV.CompanyName = this.encryptStorage.getItem('000343434') || ''
  }

  setUrlLS(tempUrl: string) {
  }

  getUrlLS(): any {
  }

  clearLS() {
    localStorage.clear()
  }

  clearNonSessLS() {
    localStorage.removeItem('tempUrl')
    localStorage.removeItem('HyloRefNo')
    localStorage.removeItem('ContactId')
    localStorage.removeItem('ContactMobile')
    localStorage.removeItem('ContactEmail')
  }

  setLanguage(languageObj, language?) {
    if (this.utilityService.isCordova()) {
      Preferences.set({ key: '607123901', value: languageObj });
      Preferences.set({ key: '607123902', value: language ? language : { en: {} } })
    } else {
      this.encryptStorage.setItem('607123901', languageObj)
      this.encryptStorage.setItem('607123902', language ? language : { en: {} })
    }

  }
  getLanguage() {
    if (this.utilityService.isCordova()) {
      Preferences.get({ key: "607123901" })
        .then(data => {
          if (data.value !== '') {
            this.utilityService.setLang = data
          } else {
            this.utilityService.setLang.Language = ""
          }
        })
        .catch(err => {
          this.utilityService.setLang.Language = ""
        })
      Preferences.get({ key: "607123902" })
        .then(data => {
          if (data.value !== null && data.value !== undefined && data.value !== '') {
          } else {
            GV.language = { en: {} }
          }
        })
        .catch(err => {
          GV.language = { en: {} }
        })
    } else {
      let tmp = this.encryptStorage.getItem('607123901') || ''
      GV.language = this.encryptStorage.getItem('607123902') || { en: {} }
      if (tmp !== '') {
        this.utilityService.setLang = tmp
      } else {
        this.utilityService.setLang.Language = ""
      }
    }
  }

  setSource(source: string, medium: string, campaign: string) {
    this.encryptStorage.setItem('700955926', source)
    this.encryptStorage.setItem('700951277', medium)
    this.encryptStorage.setItem('700953637', campaign)
  }

  getSource() {
    let source = this.encryptStorage.getItem('700955926') || ''
    let medium = JSON.stringify(this.encryptStorage.getItem('700951277')) || ''
    let campaign = JSON.stringify(this.encryptStorage.getItem('700953637')) || ''
    return { source, medium, campaign }
  }


}




