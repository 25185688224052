import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() {

  }

  encrypt2(result1) {
    return CryptoJS.AES.encrypt(result1, 'p3LQ5UxcbMV5zpz3f2QphNgyFEpZILyH').toString()
  }

  decrypt2(result1) {
    var bytes = CryptoJS.AES.decrypt(result1, 'p3LQ5UxcbMV5zpz3f2QphNgyFEpZILyH');
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  decrypt(result1) {
    var tmpdata = CryptoJS.enc.Base64.parse(atob(result1));
    var iv = CryptoJS.lib.WordArray.create(tmpdata.words.slice(0, 4));
    var ct = CryptoJS.lib.WordArray.create(tmpdata.words.slice(4));
    var tmpResult = CryptoJS.AES.decrypt(
      { ciphertext: ct },
      CryptoJS.enc.Utf8.parse("p3LQ5UxcbMV5zpz3f2QphNgyFEpZILyH"),
      { iv: iv }
    );
    return tmpResult.toString(CryptoJS.enc.Utf8);
  }

  encrypt(result1) {
    var key = CryptoJS.enc.Utf8.parse('p3LQ5UxcbMV5zpz3f2QphNgyFEpZILyH');
    var iv = CryptoJS.enc.Utf8.parse('');
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(result1), key,
      {
        keySize: 256 / 8,
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return btoa(encrypted.ciphertext.toString())
  }
}
