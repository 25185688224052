import { Injectable } from '@angular/core';
import { Enums } from '../enums';
import { GV } from "src/app/variables";

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  /* isNumeric() returns true if the string contains only numeric[0-9] else false*/
  isNumeric(value: any): boolean {
    let format = /^[0-9]*$/gm;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  /* isAlphabetic() returns true if the string contains only alphabetics[a-zA-Z] else false*/
  isAlphabetic(value: any): boolean {
    let format = /^[a-zA-Z]*$/gm;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  /* isAlphaNumeric() returns true if the string contains only alphaNumeric[a-zA-Z0-9] else false*/
  isAlphaNumeric(value: any): boolean {
    let format = /^[a-zA-Z0-9 ]*$/gm;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  isMaxUploadSize(fileSize, fileType): boolean {
    let fileSizeInKB = (fileSize / 1024);
    if (fileType === "img") {
      if (Enums.IMAGE_SIZE_TYPE === 'MB') {
        fileSizeInKB = (fileSizeInKB / 1024);
      }
      if (fileSizeInKB > Enums.IMAGE_SIZE) {
        return true;
      } else {
        return false;
      }
    } else if (fileType === "pdf") {
      if (Enums.PDF_SIZE_TYPE === 'MB') {
        fileSizeInKB = (fileSizeInKB / 1024);
      }
      if (fileSizeInKB > Enums.PDF_SIZE) {
        return true;
      } else {
        return false;
      }
    } else if (fileType === "excel") {
      if (Enums.EXCEL_SIZE_TYPE === 'MB') {
        fileSizeInKB = (fileSizeInKB / 1024);
      }
      if (fileSizeInKB > Enums.EXCEL_SIZE) {
        return true;
      } else {
        return false;
      }
    }
  }

  /* validateMobile() returns true if the mobile contains only numeric[0-9] and validates 1st digite*/
  validateMobile(mobile: any): boolean {
    if (!this.isNumeric(mobile)) {
      return false;
    } else if (mobile.length === 1) {
      if (+mobile < 6) {
        return false;
      }
    } else if (mobile.length === 11) {
      return false;
    }
    return true;
  }

  validateMaxTxnAmount(value: any, maxAmt: any): boolean {
    let format = /^(?:[0-9]+(?:\.[0-9]{0,2})?)?$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      let totalAmount = parseFloat(value);
      if (totalAmount > parseFloat(maxAmt)) {
        return false;
      }
      if (value.length === 1 && +value === 0) {
        return false;
      }
      return true;
    }
  }

  validateDecimalQuantity(value: any): boolean {
    let format = /^(?:[0-9]+(?:\.[0-9]{0,2})?)?$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      if (value.length === 1 && +value === 0) {
        return false;
      }
      return true;
    }
  }

  validateUTRNo(value: any): boolean {
    if (value.length > 22) {
      return false;
    }
    return true;
  }

  validateUpdateAmount(value: any, dueAmt: any): boolean {
    let format = /^(?:[0-9]+(?:\.[0-9]{0,2})?)?$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      let totalAmount = parseFloat(value);
      if (totalAmount > dueAmt) {
        return false;
      }
      if (value.length === 1 && +value === 0) {
        return false;
      }
      return true;
    }
  }

  validateNumericWithMaxNo(value: any, maxNo: any): boolean {
    let format = /^[0-9]*$/gm;
    if (!format.test(value)) {
      return false;
    } else {
      let totalCountLimit = parseInt(value);
      if (totalCountLimit > parseInt(maxNo)) {
        return false;
      }
      if (value.length === 1 && +value === 0) {
        return false;
      }
      return true;
    }
  }

  validateIfscCode(ifsc) {
    if (ifsc.match(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/)) {
      return true;
    }
    return false;
  }

  validateYesBankIfscCode(ifsc) {
    if (ifsc.match(/[YESB][0][a-zA-Z0-9]{6}$/)) {
      return true;
    }
    return false;
  }

  validateSellingPrice(value: any): boolean {
    let format = /^(?:[0-9]+(?:\.[0-9]{0,2})?)?$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      if (value.length === 1 && +value === 0) {
        return false;
      }
      let sellingPrice = parseFloat(value);
      if (sellingPrice > Enums.Max_Txn_Amount) {
        return false;
      }
      return true;
    }
  }

  validateChargeValue(value: any): boolean {
    let format = /^(?:[0-9]+(?:\.[0-9]{0,2})?)?\%?$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  isAlphaNumericsp(value: any): boolean {
    let format = /^[a-zA-Z0-9]{1}[A-Za-z0-9/_-]*$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  ValidateHSNCode(value: any): boolean {
    if (!this.isNumeric(value)) {
      return false;
    }
    let format = /^(?!0+$)([0-9]{4}|[0-9]{6}|[0-9]{8})$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  ValidateTransporterDocNo(value: any): boolean {
    let format = /^[[A-Za-z0-9]{1}[A-Za-z0-9\/\-]{0,14}]*$/gm;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  ValidateTransporterVachicalNo(value: any): boolean {
    let format = /^[[A-Za-z]{2}[0-9A-Za-z]{5,13}]*$/gm;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  validateEmail(value: any): boolean {
    let format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/gim;
    if (!format.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  validateGST(value: any): boolean {
    if (value !== "" && value !== undefined) {
      value = value.toUpperCase()
    }
    let GstRegEx = /^[0-9]{2}([A-Z]{5}[0-9]{4}[A-Z]{1})[0-9]{1}[A-Z0-9]{1}([A-Z0-9]{1})$/gm;
    if (!GstRegEx.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  validatePAN(value: any): boolean {
    if (value !== "" && value !== undefined) {
      value = value.toUpperCase()
    }
    let PanRegEx = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm;
    if (!PanRegEx.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  /* will not allow <>=#`~|;:" values */
  validateText(value: any) {
    value = value.replace(/[<>'`~"{}|[\]\\]/g, "")
    // to prevent consecutive spaces
    value = value.replace(/ +/g, " ");
    return value

  }

  /* will not allow all special characters except underscore(_), hyphen(-), forward slash(/) and parenthesis().
  Also, the consecutive occurrence of these two characters are restricted. */
  validateText1(value: any) {
    value = value.replace(/[.,'*+?^$<>=+#@!%&`~|;:"{}|[\]\\]/g, "");
    value = value.replace(/-+/g, "-");
    value = value.replace(/_+/g, "_");
    // to prevent consecutive spaces
    value = value.replace(/ +/g, " ");
    // to prevent consecutive forward slashes
    value = value.replace(/\/+/g, "/");
    return value;
  }

  /* will not allow all special characters
  except numbers and alphabets*/
  validateText2(value: any) {
    value = value.replace(/[.,'_*+?^$<>=+/#@!%&`~|;:"\-{}()|[\]\\]/g, "")
    // to prevent consecutive spaces
    value = value.replace(/ +/g, " ");
    return value
  }
  /* will not allow all special characters and alphabets
  except dot and numbers*/
  validateText3(value: any) {
    value = value.replace(/^(?:[A-Z]+(?:\.[a-z]{0,2})?)?$/gim, "")
    // to prevent consecutive spaces
    value = value.replace(/ +/g, " ");
    return value
  }
  /* will not allow all special characters and alphabets
  except numbers*/
  validateText4(value: any) {
    value = value.replace(/[.,'_*+?^$<>=+/#@!%&`~[A-Z a-z\]|;:"{}()|[\]\-\\]/g, "")
    // to prevent consecutive spaces
    value = value.replace(/ +/g, " ");
    return value
  }
  /* will not allow all special characters and numbers
  except alphabets*/
  validateText5(value: any) {
    value = value.replace(/[.,'_*+?^$<>=+/#@!%&`~[0-9]|;:"{}()|[\]\-\\]/g, "")
    // to prevent consecutive spaces
    value = value.replace(/ +/g, " ");
    return value
  }

  /* will not allow all special characters except slash /. */
  validateText6(value: any) {
    value = value.replace(/[.,'_*+?^$<>=+#@!%&`~|;:"{}()|[\]\-\\]/g, "");
    // to prevent consecutive spaces
    value = value.replace(/ +/g, " ");
    return value;
  }

  validateCIN(value) {
    if (value !== "" && value !== undefined) {
      value = value.toUpperCase()
    }
    let PanRegEx = /^([LlUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/gm;
    if (!PanRegEx.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  isValueEmpty(value) {
    return value === undefined || value === '' || value === null;
  }

  isValueNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
  }

}
